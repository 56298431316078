import { useContext, useEffect, useState } from "react";
import DiscoveryContext from "./DiscoveryContext";
import { ScrollArea } from "@mantine/core";
import EvidenceCardDiscovery from "./EvidenceCardDiscovery";

const EvidenceList = () => {

  const { displayedMatches, displayedRequestsMatches } = useContext(DiscoveryContext);

  const [matches, setMatches] = useState<any[]>([]);

  useEffect(() => {
    const concated = [...displayedMatches, ...displayedRequestsMatches]
    const sortedMatches = concated.sort((a: any, b:any) => a.yes - b.yes)
    const sliced = sortedMatches.slice(0,50)
    setMatches(sliced)
    
  }, [displayedMatches, displayedRequestsMatches]);

  return (
    <ScrollArea pt="24px" px={36} w="100%" h={`calc(100vh - 517px)`} mah={`calc(100vh - 517px)`}>

      {matches?.length > 0 &&
          matches?.map((evidence: any, index: number) => (
            <EvidenceCardDiscovery
              key={evidence._id}
              item={evidence}
              index={index}
              fromSearch={false}
            />
          )) 
      }
    </ScrollArea>
  )
}

export default EvidenceList