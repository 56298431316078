import { Box, Paper, Switch, Table } from "@mantine/core";
import { useEffect, useState } from "react";

import { getIdeaPageDisplay, updateIdeaPageDisplay } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import { SourceIcon } from "../../../utils/SourceIcon";

function Display() {
  const auth0 = useAuth0();

  interface Attribute {
    value: string;
    name: string;
  }

  interface DisplayState {
    [value: string]: boolean;
  }

  const attributes: Attribute[] = [
    { value: "rolloutDate", name: "Expected delivery date" },
    { value: "status", name: "Status" },
    { value: "priority", name: "Priority" },
    { value: "component", name: "Product Areas" },
    { value: "domain", name: "Domain" },
    { value: "delivery", name: "Delivery" },
    { value: "committed", name: "Committed" },
  ];

  const [displayState, setDisplayState] = useState<DisplayState>({});

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const loadIdeaPageDisplay = () => {
    getIdeaPageDisplay('idea', auth0)
      .then((res) => {
        setDisplayState(res);
      })
      .catch((e) => console.log(e));
  };

  const updateDisplay = (value: string, use: boolean) => {
    const update = { value: value, use: use };
    updateIdeaPageDisplay(update, auth0)
      .then(() => saveNotification())
      .catch((e) => console.log(e));
  };

  const handleChange = (attr: Attribute) => {
    setDisplayState((prevState) => {
      /* updateDisplay(attr.value, !prevState[attr.value]); */
      return {
        ...prevState,
        [attr.value]: !prevState[attr.value],
      };
    });
    updateDisplay(attr.value, !displayState[attr.value]);
  };

  useEffect(() => {
    loadIdeaPageDisplay();
  }, []);

  return (
    <Box
      p={"xl"}
      mt={7}
      bg={"#F1F3F5"}
      sx={{ height: "calc(100vh - 152.34px)" }}
    >
      <Paper mt={"lg"} withBorder>
        <Table>
          <thead>
            <tr style={{ backgroundColor: "#f3f3f3" }}>
              <th style={{ width: "250px", padding: "12px" }}>Attribute</th>
              <th style={{ width: "50px", padding: "12px" }}>Display</th>
            </tr>
          </thead>
          <tbody>
            {attributes.map((attr: any) => {
              return (
                <tr key={attr.name}>
                  <td
                    style={{
                      width: "250px",
                      textAlign: "left",
                      paddingLeft: "30px",
                      padding: "12px",
                    }}
                  >
                    {attr.name}
                  </td>
                  <td
                    style={{
                      width: "50px",
                      textAlign: "left",
                      paddingLeft: "30px",
                      paddingRight: 0,
                    }}
                  >
                    <Switch
                      color="indigo"
                      size="md"
                      onLabel="ON"
                      offLabel="OFF"
                      checked={displayState[attr.value]}
                      onChange={() => handleChange(attr)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Paper>
    </Box>
  );
}

export default Display;
