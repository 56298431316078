import React, { useContext } from "react";
import IdeasContext from "../../IdeasContext";
import { formatBigMonetaryValueIdeas } from "../../ideas-table-helpers/Helpers";
import { Flex, Text, Tooltip } from "@mantine/core";
import { capitalizeFirstLetters, updateAccessorByState } from "../../../../utils";

// Type definitions for component props
interface DynamicCellProps {
  data: Record<string, any>;
  accessor: string;
  renderParams?: {
    prefix?: string;
    default?: string;
    multiplyByComponentWeight?: boolean;
  };
}

// Main component
export const DynamicCell: React.FC<DynamicCellProps> = ({
  data,
  accessor,
  renderParams = {},
}) => {
  const { sortState } = useContext(IdeasContext);
  const multiplyByComponentWeight = renderParams?.multiplyByComponentWeight || false;

  // Helper function to safely access nested values
  const getNestedValue = (obj: Record<string, any>, path: string): any => {
    return path
      .split(".")
      .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
  };

  // Update accessors based on the current sort state
  const totalAccessor = updateAccessorByState(accessor, "total");
  const assignedAccessor = updateAccessorByState(accessor, "assigned");
  const suggestedAccessor = updateAccessorByState(accessor, "suggested");

  // Retrieve values from the data object
  const totalValue = getNestedValue(data, totalAccessor);
  const assignedValue = getNestedValue(data, assignedAccessor);
  const suggestedValue = getNestedValue(data, suggestedAccessor);
  
  // console.log({ data, componentObj: data.componentObj });

  // Retrieve component weight, defaulting to 1 if not present
  const componentWeight = data.componentObj?.weight || 1;

  // Calculate multiplied values if required
  const multipliedTotalValue = multiplyByComponentWeight ? totalValue * componentWeight : totalValue;
  const multipliedAssignedValue = multiplyByComponentWeight ? assignedValue * componentWeight : assignedValue;
  const multipliedSuggestedValue = multiplyByComponentWeight ? suggestedValue * componentWeight : suggestedValue;

  // Determine what to display based on sortState
  let displayContent;

  switch (sortState) {
  case "total":
    displayContent = (
      <Tooltip label="Total (Assigned)" withArrow>
        <Flex align="center">
          {/* Total Value with Tooltip */}
          <Text weight={600} color="#5C5CEB">
            {formatBigMonetaryValueIdeas(multipliedTotalValue || 0, renderParams.prefix)}
          </Text>

          {/* Assigned Value in Parentheses with Tooltip */}
          <Text pl={5} weight={400} color="#5C5CEB">
              ({formatBigMonetaryValueIdeas(multipliedAssignedValue || 0, renderParams.prefix)})
          </Text>
        </Flex>
      </Tooltip>
    );
    break;

  case "assigned":
    displayContent = (
      <Tooltip label={capitalizeFirstLetters("assigned")} withArrow>
        <Text weight={600} color="#5C5CEB">
          {formatBigMonetaryValueIdeas(multipliedAssignedValue || 0, renderParams.prefix)}
        </Text>
      </Tooltip>
    );
    break;

  case "suggested":
    displayContent = (
      <Tooltip label={capitalizeFirstLetters("suggested")} withArrow>
        <Text weight={600} color="#5C5CEB99">
          {formatBigMonetaryValueIdeas(multipliedSuggestedValue || 0, renderParams.prefix)}
        </Text>
      </Tooltip>
    );
    break;

  default:
    displayContent = <Text>{renderParams.default || "-"}</Text>;
  }

  return <Flex justify="center">{displayContent}</Flex>;
};