import {
  Autocomplete,
  Avatar,
  Button,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import React, { forwardRef } from "react";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { useForm } from "@mantine/form";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import { upsertDomain } from "../../../../Api";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  picture: string;
  name: string;
  email: string;
}

function AddDomainRow({
  members,
  setAddDomainIsOpen,
  setAddDomain,
}: {
  members: any;
  setAddDomainIsOpen: any;
  setAddDomain: any;
}) {
  const auth0 = useAuth0();
  console.log(members);
  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const form = useForm({
    initialValues: {
      name: "",
      value: "",
      _id: "",
      ownerId: "",
      ownerName: "",
    },
  });

  const sendSavedData = async (updateData: any) => {
    try {
      const body = { updateData };
      await upsertDomain(auth0, body).then((res) => {
        setAddDomain(updateData);
        saveNotification();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (formValues: any) => {
    sendSavedData(formValues);
    setAddDomainIsOpen(false);
  };

  const handleRemoveAddRow = (formValues: any) => {
    if (formValues.name.length === 0 && formValues.value.length === 0) {
      setAddDomainIsOpen(false);
    } else {
      if (
        confirm("Are you sure you want to delete this product area?") === true
      ) {
        form.reset();
        setAddDomainIsOpen(false);
      }
    }
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ picture, name, email, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <Avatar src={picture} radius="xl" />

            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {email}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  return (
    <tr key={"AddDomainRow"}>
      <td
        width={"150px"}
        style={{
          textAlign: "left",
        }}
        {...form.getInputProps("name")}
      >
        <TextInput
          {...form.getInputProps("name")}
          placeholder="Add product domain here..."
          icon={
            <Text fz="lg" c="red">
              *
            </Text>
          }
        />
      </td>
      <td width={"150px"} style={{ textAlign: "left" }}>
        <TextInput
          {...form.getInputProps("value")}
          placeholder="Add description here..."
        />
      </td>
      <td width={"150px"}>
        {members && members.length > 0 && (
          <Autocomplete
            placeholder="Select owner"
            itemComponent={SelectItem}
            value={form.values.ownerName || ""}
            onChange={(val) => {
              form.setFieldValue("ownerName", val);
            }}
            onItemSubmit={(item) => {
              form.setFieldValue("ownerId", item._id);
              form.setFieldValue("ownerName", item.name);
            }}
            data={members}
            nothingFound={
              <Button fullWidth disabled leftIcon={<IconPlus size={18} />}>
                Invite member
              </Button>
            }
            filter={(value, user) =>
              (user.name &&
                user.name.toLowerCase().includes(value.toLowerCase().trim())) ||
              (user.email &&
                user.email.toLowerCase().includes(value.toLowerCase().trim()))
            }
            error={form.errors.ownerName || form.errors.ownerId}
          />
        )}
      </td>
      <td width={"60px"}>
        <Button
          variant="subtle"
          color="dark"
          aria-label="Save"
          onClick={() => handleSave(form.values)}
          disabled={form.values.name.length > 0 ? false : true}
        >
          Save
        </Button>

        <Button
          variant="subtle"
          color="dark"
          leftIcon={<SourceIcon sourceName={"bin"} />}
          aria-label="Remove"
          onClick={() => handleRemoveAddRow(form.values)}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

export default AddDomainRow;
