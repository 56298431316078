import { useContext, useEffect, useState } from 'react'
import IdeaContext from '../IdeaContext';
import {Text, Flex, Stack, Box, Tooltip, Menu, ActionIcon} from '@mantine/core';
import {IconAlertOctagon, IconUser, IconUsers, IconX} from '@tabler/icons';
import { ProductAreaIcon } from '../../../icons/x-symbol-svgrepo-com';
import SyncContext from '../../../context/SyncContext';
import BagelAutocomplete from '../../general/BagelAutocomplete';
import { InfoCard } from '../../idea/InfoCard';
import { saveIdea } from '../../../Api';
import { useAuth0 } from '@auth0/auth0-react';
import {showNotification} from "@mantine/notifications";
import {SourceIcon} from "../../../utils/SourceIcon";
import { DeleteIdea } from './DeleteIdea';
import { LinkProduct } from './LinkProduct';


const IdeaAttributes = ({reviewMode ,setReviewMode}: {reviewMode: boolean ,setReviewMode : any}) => {

  const {
    displayState,
    components,
    ideaPriorities,
    domains,
    ideaStatuses,
    owners,
    sync
  } = useContext(SyncContext);

  const { idea } = useContext(IdeaContext);

  

  const auth0 = useAuth0();

  const domainsNames = domains.map((d: any) => d.name)
  const selectedStatus = ideaStatuses?.find(
    (status: any) => status.key?.toLowerCase() == idea?.status?.toLowerCase()
  );
  const showRequestProductLink = true//TODO: get this from proper place
  const isStatusInDelivery: boolean =
    selectedStatus?.key ==
    ideaStatuses?.filter((s: any) => s.isDefaultDelivery)?.[0]?.key;
  const hasProductItem = idea?.productItem !== undefined
  const hasLink = showRequestProductLink && (idea?.origin === "clickup" || idea?.origin === "jira") && idea?.link !== undefined
  const productItem = idea?.productItem || (hasLink ? idea : undefined)

  const selectableStatuses =
    ideaStatuses?.filter((i: any) => !i?.isDefaultDelivery) || [];

  const [priority, setPriority] = useState(idea?.priority || 'Unassigned')
  const [domain, setDomain] = useState(idea?.componentObj?.domain || 'Unassigned');
  const [componentName, setComponentName] = useState(idea?.componentObj?.name || 'Unassigned');
  const [ownerName, setOwnerName] = useState(idea?.owner?.name || 'Unassigned');
  const [status, setStatus] = useState(idea?.status || 'Unassigned');
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(idea?.components);
  const [loadingSync, setLoadingSync] = useState(true);
  const hasOrigin: boolean =
  idea?.origin === "jira" ||
  idea?.origin === "clickup" ||
  idea?.origin === "productboard";

  const onSelectSave = async (type: string, value: string) => {
    // console.log(type, value)
    if (idea) {
      const ideaObj = {
        id: idea._id,
        [type]: value,
      };
      const response = saveIdea(ideaObj, auth0)
      return response
    }
  };

  const filterComponentByDomain = (domainValue: string) => {
    const copy = [...components];
    const arr: any[] = copy.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);  
  };

  useEffect(() => {
    setPriority(idea?.priority || 'Unassigned')
    setDomain(idea?.componentObj?.domain || 'Unassigned')
    setOwnerName(idea?.owner?.name || 'Unassigned')
    setStatus(idea?.status || 'Unassigned');
    setComponentsByDomain(idea?.components)
    setComponentName(idea?.componentObj?.name || 'Unassigned')

  }, [idea]);

  useEffect(() => {
    if (sync) {
      setLoadingSync(false);
    }
  }, [sync]);



  return (
   
      
    <Flex align='center' justify='flex-end'>
        
      {/* Domain*/}

      {displayState?.domain &&(
        <Menu>
          <Menu.Target>
            <Tooltip label={
              <Box p={0.186}>
                <Text fz={11.4} mb={1.4}>Domain:</Text>
                <Text fz={11.4}>{domain}</Text>
              </Box>
          
            }>
              <Flex
                h={24}
                px={8}
                py={4}
                mx={3}
                bg="#5C5CEB1A"
                justify="space-evenly"
                align="center"
                sx={{ borderRadius: "8px", border: "0.8px solid #F8e9FB", cursor: 'pointer' }}
              >
                <Box mt="4px">
                  <Text mr={7} mt={2}>
                    {" "}
                    <ProductAreaIcon color="black" />{" "}
                  </Text>
                </Box>
                <Text fz="12px" lineClamp={1} maw="100px">
                  {domain || "Unassigned"}
                </Text>
              </Flex>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            {domainsNames.map((item: string) => (
              <Menu.Item
                w="180px"
                key={item}
                onClick={async () => {
                  setDomain(item);
                  filterComponentByDomain(item)
                  setComponentName(undefined)
                }}
                bg={item=== domain ? "#f0f0f0" : "transparent"}
              >
                {item}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )} 

      {/* Product area */}

      {displayState?.component && (
        <BagelAutocomplete
          disabled={hasOrigin || (displayState?.domain && !domain)}
          items={
            displayState?.domain ? componentsByDomain : components
          }
          selected={componentName}
          onSelect={async (item) => {
            const oldValue = componentName
            setComponentName(item.name)
            const res = await onSelectSave('componentId', item._id ||'')
            if (res?.data.success !== true) {
              setComponentName(oldValue)
              showNotification({
                title: "Error saving product area",
                color: "red",
                message: "please try again...",
                icon: <IconX size="1.1rem" />,
                autoClose: 1200
              });
            }
          }}
        >
          <Tooltip label={
            <Box p={0.186}>
              <Text fz={11.4} mb={1.4}>Product area:</Text>
              <Text fz={11.4}>{componentName}</Text>
            </Box>
          
          }>   
            <Flex
              h={24}
              px={8}
              py={4}
              mx={3}
              bg="#5C5CEB1A"
              justify="space-evenly"
              align="center"
              sx={{ borderRadius: "8px", border: "0.8px solid #F8e9FB" ,cursor: 'pointer' }}
            >
              <Box mt="4px">
                <Text mr={7} mt={2}>
                  {" "}
                  <ProductAreaIcon color="black" />{" "}
                </Text>
              </Box>
              <Text fz="12px" lineClamp={1} maw="100px"  tt="capitalize">
                {componentName || "Unassigned"}
              </Text>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>
      )} 

     

      


      {/* Owner */}
      
      <BagelAutocomplete
        items={owners.map((member: any) => ({
          name: member.name,
          id: member._id,
        }))}
        selected={ownerName}
        onSelect={async (item: any) => {
          const oldValue = ownerName
          setOwnerName(item?.name);
          const res = await onSelectSave('ownerId', item.id)
          if (res?.data?.success !== true) {
            setOwnerName(oldValue)
            showNotification({
              title: "Error saving owner",
              color: "red",
              message: "please try again...",
              icon: <IconX size="1.1rem" />,
              autoClose: 1200
            });
          }
        }}
      >
        <Tooltip 
          label={
            <Box p={0.186}>
              <Text fz={11.4} mb={1.4}>Owner:</Text>
              <Text fz={11.4}>{ownerName}</Text>
            </Box>
          
          }>
          <Flex
            h={24}
            px={8}
            py={4}
            mx={3}
            bg="#5C5CEB1A"
            justify="space-evenly"
            align="center"
            sx={{ borderRadius: "8px", border: "0.8px solid #F8e9FB",cursor: 'pointer' }}
          >
            <Box mt="4px" mr={4}>
              <IconUser size="1.1rem" />
            </Box>
            <Text fz="12px" lineClamp={1} maw="100px">
              {ownerName || ""}
            </Text>
          </Flex>
        </Tooltip>
      </BagelAutocomplete>
      {/* status */}

    

      {displayState?.status && (
        <Menu shadow="md" width={200} disabled={isStatusInDelivery}>
          <Menu.Target>
            <Tooltip label={
              <Box p={0.186}>
                <Text fz={11.4} mb={1.4}>Status:</Text>
                <Text fz={11.4}>{status}</Text>
              </Box>
          
            }>
              <Flex
                h={24}
                px={8}
                py={4}
                bg="#5C5CEB1A"
                mx={3}
                justify="center"
                align="center"
                sx={{ borderRadius: "8px", border: "0.8px solid #F8e9FB" ,cursor: (hasOrigin || isStatusInDelivery)?"default" : 'pointer'}}
              
                
              >
                <Text fz="12px" lineClamp={1} maw="100px" tt="capitalize">
                  {status || ""}
                </Text>
              </Flex>
            </Tooltip>
          </Menu.Target>

          {!hasOrigin && (
            <Menu.Dropdown>
              <Menu.Label>Choose one...</Menu.Label>
              {selectableStatuses &&
                            selectableStatuses.length > 0 &&
                            selectableStatuses?.map((item: any) => (
                              <Menu.Item
                                key={item.key}
                                onClick={async () => {
                                  const oldValue = status
                                  setStatus(item.key)
                                  const res = await onSelectSave("status", item.key)
                                  if (res?.data?.success !== true) {
                                    setStatus(oldValue)
                                    showNotification({
                                      title: "Error saving status",
                                      color: "red",
                                      message: "please try again...",
                                      icon: <IconX size="1.1rem" />,
                                      autoClose: 1200
                                    });
                                  }
                                }
                                 
                                
                                }
                                tt={"capitalize"}
                                bg={item?.label === status ? "#f0f0f0" : "transparent"}
                              >
                                {item?.label}
                              </Menu.Item>
                            ))}
            </Menu.Dropdown>
          )}
        </Menu>
      )} 

      {/* Priority */}

      {displayState?.priority &&
      <Menu>
        <Menu.Target>
          <Tooltip label={
            <Box p={0.186}>
              <Text fz={11.4} mb={1.4}>Priority:</Text>
              <Text fz={11.4}>{priority}</Text>
            </Box>
          
          }>
            <Flex
              h={24}
              px={8}
              py={4}
              mx={6}
              bg="#5C5CEB1A"
              justify="space-evenly"
              align="center"
              sx={{ borderRadius: "8px", border: "0.8px solid #F8e9FB" ,cursor: 'pointer'}}
            >
              <Box mt="4px" mr={5}>
                <IconAlertOctagon size={14} />
              </Box>
              <Text fz="12px" lineClamp={1} maw="100px"  tt="capitalize">
                {priority || ""}
              </Text>
            </Flex>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          {ideaPriorities?.map((item: any) => (
            <Menu.Item
              w="180px"
              key={item}
              onClick={async () => {
                const oldValue = priority
                setPriority(item.label);
                const res = await onSelectSave("priority", item.label)
                if (res?.data?.success !== true) {
                  setPriority(oldValue)
                  showNotification({
                    title: "Error saving priority",
                    color: "red",
                    message: "please try again...",
                    icon: <IconX size="1.1rem" />,
                    autoClose: 1200
                  });
                }
              }}
              bg={item?.label === priority ? "#f0f0f0" : "transparent"}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>  
      
      }

      {/* {displayState?.delivery && (
        <Menu>
          <Menu.Target>
            <Tooltip label={productItem?.status || 'Delivery'}>
              <Flex
                h={24}
                px={8}
                py={4}
                mx={3}
                bg="#5C5CEB1A"
                justify="space-evenly"
                align="center"
                sx={{ borderRadius: "8px", border: "0.8px solid #F8e9FB" ,cursor: 'pointer'}}
              >
                <Box mt="4px" mr={5}>
                  <SourceIcon
                    sourceName={sync ?? "jira"}
                    width={15}
                    height={15}
                  />
                </Box>
                <Text fz="12px" lineClamp={1} maw="100px"  tt="capitalize">
                  {productItem?.itemKey || "Unassigned"}
                </Text>
              </Flex>
            </Tooltip>
          </Menu.Target>
          <Menu.Dropdown>
            <></>
          </Menu.Dropdown>
        </Menu>
      )} */}

      {displayState?.delivery && sync ? (
        <LinkProduct
          request={idea}
          showRequestProductLink={showRequestProductLink}
          itemKey={idea?.itemKey ?? ""}
          originType={idea?.originType}
        />
      ) : (
        displayState?.delivery && (
          <ActionIcon loading={loadingSync} >
            <InfoCard
              label={sync ?? "jira"}
              text="Link"
              icon={
                <SourceIcon
                  sourceName={sync ?? "jira"}
                  width={25}
                  height={25}
                />
              }
              cardProps={{ opacity: 0.5 }}
            />
          </ActionIcon>
        )
      )} 
      <Stack align='center' ml={3}>
        <DeleteIdea/>
      </Stack>
      
    </Flex>

  );
}

export default IdeaAttributes



















{/* {reviewMode ? ( */}
{/* <Flex
        fz="12px"
        h="20px"
        sx={{
          transition: "opacity 0.4s ease-in-out",
          opacity: reviewMode ? 1 : 0,
        }}
      >
        <Text w="19%" color="dimmed">
          Status
        </Text>
        <Text w="17%" color="dimmed" mx="xs">
          Exp. Delivery date
        </Text>
        <Text w="14%" color="dimmed">
          Priority
        </Text>
        <Text w="14%" color="dimmed" mx="xs">
          Owner
        </Text>
        <Text w="14%" color="dimmed">
          Product area
        </Text>
      </Flex> */}
{/* ) : (
        <Flex h="20px"> </Flex>
      )} */}
{/*All of this is one button  */}




{/* <Flex
          h="36px"
          w="19%"
          bg="#76ABF0"
          align="center"
          sx={{ borderRadius: "8px" }}
        >
          <Stack
            h="30px"
            fz="12px"
            sx={{ borderRight: "0.8px solid #F8e9FB" }}
            justify="center"
            w="120px"
            align="center"
            onClick={() => {
              setReviewMode(!reviewMode);
            }}
          >
            <Text color="white">START REVIEW</Text>
          </Stack>
        </Flex> */}

        
{/* <Box mx="xs" mt="4px">
          <IconCalendarTime size="1.1rem" />
        </Box> */} 
        
// For status
/* <UnstyledButton
              sx={
                hasOrigin || isStatusInDelivery
                  ? { cursor: "default" }
                  : { cursor: "pointer" }
              }
               disabled={isStatusInDelivery} 
            >
              <InfoCard
                label="Status"
                text={selectedStatus?.key ?? "Unassigned"}
                width="100%"
                cardProps={{
                  opacity: isStatusInDelivery ? 0.5 : 1,
                }}
              />
            </UnstyledButton> */
//Design for product area
{/* <UnstyledButton
            sx={
              idea?.itemKey ||
            (!domain && displayState?.domain) ||
            domain === "Unassigned"
                ? { width: "calc(20% - 12px)", cursor: "default" }
                : { width: "calc(20% - 12px)" }
            }
          >
            <InfoCard
              label="Product area"
              text={idea?.componentObj?.name || "Unassigned"}
              width="100%"
              sx={{
                opacity: !domain && displayState?.domain ? 0.5 : 1,
              }}
            />
          </UnstyledButton> */}