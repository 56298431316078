import {
  Text,
  Center,
  Flex,
  Box,
  Stack,
  Tooltip,
  HoverCard,
  Group,
} from "@mantine/core";
import { useContext, useEffect, useState, memo } from "react";
import { PieChart, Pie, Cell } from "recharts";
import DiscoveryContext from "../DiscoveryContext";
import { formatBigMonetaryValue, formatMonetary } from "../../ideaNiv/Funcs";



interface ImpactPieChartProps {
  title: string[];
  validatedColor: string;
  pendingColor: string;
  monetaryValue: number;
  validatedMonetaryValue: number;
  customerCount: number;
  validatedCustomerCount: number;
  prefix?: string;
}

const ImpactPieChartDiscovery2 = memo(
  ({
    title,
    validatedColor,
    pendingColor,
    monetaryValue,
    validatedMonetaryValue,
    customerCount,
    validatedCustomerCount,
    prefix
  }: ImpactPieChartProps) => {
    const { showmatches, showValidated } = useContext(DiscoveryContext);

    // Pre-compute values for better readability and performance
    const totalMonetaryValue = monetaryValue + validatedMonetaryValue;
    const totalCustomerCount = customerCount + validatedCustomerCount;

    // Calculate percentages based on monetary values
    const totalValue = validatedMonetaryValue + monetaryValue;
    const validatedPercentage =
      totalValue === 0 ? 0 : (validatedMonetaryValue / totalValue) * 100;
    const pendingPercentage = 100 - validatedPercentage;

    const pendingMonetaryValue = monetaryValue;
    const pendingCustomerCount = customerCount;

    const data = [
      { name: "Validated", value: validatedPercentage },
      { name: "Pending", value: pendingPercentage },
    ];

    const COLORS = [validatedColor, pendingColor];
    const [chartKey, setChartKey] = useState(0);

    useEffect(() => {
      setChartKey((prevKey) => prevKey + 1);
    }, [monetaryValue, customerCount, validatedCustomerCount]);

    return (
      <Box /* pl={20} */>
        <Center style={{ position: "relative", width: 164, height: 164 }}>
          <PieChart key={chartKey} width={164} height={164}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={82}
              innerRadius={75}
              dataKey="value"
              startAngle={90}
              endAngle={450}
              stroke="none"
              isAnimationActive
              animationDuration={800}
            >
              {data.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>

          <Stack
            justify="center"
            align="center"
            spacing={0}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <Stack justify="center" align="center" spacing={0}>
              <Text fw={600} fz={14} color="#212529">
                {title[0]}
              </Text>
              <Text fw={600} fz={14} color="#212529" mb={6}>
                {title[1]}
              </Text>
            </Stack>

            <HoverCard
              offset={10.5}
              disabled={true}
              transition="pop"
              transitionDuration={104}
            >
              <HoverCard.Target>
                <Flex align="center" justify="center" w="100%">
                  <Tooltip
                    label={formatBigMonetaryValue(totalMonetaryValue, prefix)}
                  >
                    <Text
                      fw={600}
                      fz={13}
                      color="#5C5CEB"
                      style={{ width: 60, textAlign: "right" }}
                      lineClamp={1}
                    >
                      {formatBigMonetaryValue(totalMonetaryValue, prefix)}
                    </Text>
                  </Tooltip>
                  <Box
                    sx={{
                      width: "4.74px",
                      height: "4.74px",
                      borderRadius: "50%",
                      backgroundColor: "#5C5CEB",
                      margin: "0 5px",
                    }}
                  />
                  <Flex style={{ width: 60, textAlign: "left" }}>
                    <Text fw={600} fz={13} color="#5C5CEB" mr="2px">
                      {totalCustomerCount}
                    </Text>
                    <Text fw={600} fz={13} color="#5C5CEB">
                    accts.
                    </Text>
                  </Flex>
                </Flex>
              </HoverCard.Target>
              <HoverCard.Dropdown bg="#F8F9FA">
                <Box w={160} pb={1}>
                  <Box w={160} mb="sm">
                    <Text fz={12} color="#212529" mb={7} align="left">
                    Assigned:
                    </Text>
                    <Group align="center" position="apart" w={160}>
                      <Text align="left" color="#5C5CEB" fz={12} w={60}>
                        {formatMonetary(validatedMonetaryValue, [
                          { threshold: 10000, suffix: '', round: true },
                          { threshold: 1000000, suffix: 'k', divisor: 1000, round: true },
                          { threshold: 99000000, suffix: 'M', divisor: 1000000, round: false }
                        ], prefix)}
                      </Text>
                      <Box
                        sx={{
                          width: 6,
                          height: 6,
                          borderRadius: "50%",
                          backgroundColor: "#5C5CEB",
                        }}
                      />
                      <Flex>
                        <Text align="right" lineClamp={1} color="#5C5CEB" fz={12} w={60}>
                          {`${validatedCustomerCount} accts`}
                        </Text>
                      </Flex>
                    </Group>
                  </Box>
                  <Box w={160}>
                    <Text fz={12} color="#212529" mb={7} align="left">
                    Suggested:
                    </Text>
                    <Group align="center" position="apart" w={160}>
                      <Text align="left" color="#5C5CEB" fz={12} w={60}>
                        {formatMonetary(pendingMonetaryValue, [
                          { threshold: 10000, suffix: '', round: true },
                          { threshold: 1000000, suffix: 'k', divisor: 1000, round: true },
                          { threshold: 99000000, suffix: 'M', divisor: 1000000, round: false }
                        ], prefix)}
                      </Text>
                      <Box
                        sx={{
                          width: 6,
                          height: 6,
                          borderRadius: "50%",
                          backgroundColor: "rgba(92, 92, 235, 0.6)",
                        }}
                      />
                      <Flex>
                        <Text align="right" lineClamp={1} color="#5C5CEB" fz={12} w={60}>
                          {`${pendingCustomerCount} accts`}
                        </Text>
                      </Flex>
                    </Group>
                  </Box>
                </Box>
              </HoverCard.Dropdown>
            </HoverCard>
          </Stack>
        </Center>
      </Box>
      
    );
  }
);

export default ImpactPieChartDiscovery2;