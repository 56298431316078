import { useAuth0 } from "@auth0/auth0-react";
import { useListState, UseListStateHandlers } from "@mantine/hooks";
import { DataTableColumn, DataTable } from "mantine-datatable";
import { useContext, useState, useEffect } from "react";
import { validateData } from "../../Api";
import SyncContext from "../../context/SyncContext";
import { Text } from "@mantine/core";
import TableCell from "./TableCell";
import { BusinessCategories } from "../../types/BusinessCategories";

export const ACCESSORS = {
  KEY: "Key",
  SFDC_ACCOUNT_ID: "SFDC Account ID",
  SFDC_OPPORTUNITY_ID: "SFDC Opportunity ID",
  ORIGIN: "Origin",
  ORIGIN_ID: "Origin ID",
  ORIGIN_LINK: "Origin Link",
  BUSINESS_CATEGORY: "Category",
  BUSINESS_NAME: "Business Name",
  TITLE: "Title",
  DESCRIPTION: 'Description',
  CREATED_AT: "createdAt",
  // DOMAIN: "Domain",
  // PRODUCT_AREA: "Product Area",
  // SUB_AREA: "Sub Area",
  ERROR: "ERROR",
};
const PAGE_SIZES = [10, 50, 100];

function CsvDataTable({
  data,
  handlers,
  loading,
  setLoading,
}: {
  data: any[];
  handlers: UseListStateHandlers<never>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const auth0 = useAuth0();
  const { fields, domains, members, allComponents } = useContext(SyncContext);
  const { ideaStatuses, ideaPriorities, requestPriorities } = fields;
  // const [loading, setLoading] = useState<boolean>(true)
  const [_validateData, setValidateData] = useState<any>({
    sfOrgValidations: [],
    userValidations: [],
    oppIdValidations: [],
    domainsComponents: [],
  });

  const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const [page, setPage] = useState(1);

  const _validate = (
    value: string,
    validValues: any,
    checkAccessor?: string
  ) => {
    return validValues.some(
      (item: any) =>
        (!checkAccessor
          ? item.label === value?.trim()
          : item[checkAccessor]?.trim() === value?.trim()) &&
        value !== null &&
        value?.trim() !== ""
    );
  };

  // Define validation criteria based on column titles
  const validationCriteria: Record<string, (value: any, row: any) => boolean> =
    {
      Key: (value) => {
        const keyCount = data.reduce(
          (count, r) => count + (r.Key === value ? 1 : 0),
          0
        );
        return keyCount <= 1 && value !== "" && value !== null;
      },
      [ACCESSORS.SFDC_ACCOUNT_ID]: (value, row) =>
        loading ||
        _validateData.oppIdValidations.find((opp: any) => opp.oppId === row[ACCESSORS.SFDC_OPPORTUNITY_ID])
          ?.valid ||
        _validateData.sfOrgValidations.find((org: any) => org.sfId?.trim() === value?.trim())
          ?.valid,
      [ACCESSORS.SFDC_OPPORTUNITY_ID]: (value, row) =>
        loading ||
        _validateData.sfOrgValidations.find((org: any) => org.sfId === row[ACCESSORS.SFDC_ACCOUNT_ID])
          ?.valid ||
        _validateData.oppIdValidations.find((opp: any) => opp.oppId === value)
          ?.valid,
      Origin: (value) => value !== "" && value !== null,
      "Origin ID": (value) => value !== "" || value === null,
      "Origin Link": (value) => value !== "" || value === null,
      "Business Name": (value) => value !== "" && value !== null,
      Category: (value) =>
        value !== "" &&
        value !== null &&
        _validate(value, BusinessCategories, "businessCategory"),
      Title: (value) => value !== "" && value !== null,
      Description: (value) => true,
      createdAt: (value) => {
        // Check if the value is a valid date
        const date = new Date(value);
        if (isNaN(date.getTime())) {
          return false; // Invalid date format
        }

        // Check if the date is in the future
        const now = new Date();
        if (date > now) {
          return false; // Future date
        }

        return true; // Valid date
      },
      // [ACCESSORS.DOMAIN]: (value, row) =>
      //   // _validate(value, domains, "name")
      //   _validateData.domainsComponents[row.index]?.[ACCESSORS.DOMAIN]?.valid ||
      //     value?.toLowerCase() == "none",
      // [ACCESSORS.PRODUCT_AREA]: (value, row) =>
      // // _validate(value, allComponents, "name") ||
      //   _validateData.domainsComponents[row.index]?.[ACCESSORS.PRODUCT_AREA]?.valid ||
      //     value?.toLowerCase() == "none",
      // [ACCESSORS.SUB_AREA]: (value, row) =>
      //   _validateData.domainsComponents[row.index]?.[ACCESSORS.SUB_AREA]?.valid ||
      //     value?.toLowerCase() == "none",
    };

  const validateValue = (columnTitle: string, value: any, row: any) => {
    const validationFunction = validationCriteria[columnTitle?.trim()];
    if (validationFunction) {
      return validationFunction(value, row);
    }
    return true; // If no validation criteria is defined, consider it valid
  };

  const validateRow = (row: any, index: number) => {
    console.log("validateRow");
    setLoading(true);
    const validatedRow = { ...row, errors: [] };

    columns.forEach(async (column: DataTableColumn<any>) => {
      const columnTitle = (column.title as string).trim();
      const value = row[column.accessor];

      if (!validateValue(columnTitle, value, row)) {
        validatedRow.errors.push(columnTitle);
      }
      //@ts-ignore
      handlers.setItemProp(index, "ERROR", validatedRow.errors.join(","));
    });
    setLoading(false);
    return validatedRow;
  };
  const extractAndValidateData = async () => {
    try {
      setLoading(true);
      const sfIds = data
        .map((item: any) => item[ACCESSORS.SFDC_ACCOUNT_ID])
        .filter((id: string) => id != null);
      const oppIds = data
        .map((item: any) => item[ACCESSORS.SFDC_OPPORTUNITY_ID])
        .filter((id: string) => id != null);

      // const domainsComponents = data.map((item: any) => ({
      //   [ACCESSORS.DOMAIN]: item[ACCESSORS.DOMAIN],
      //   [ACCESSORS.PRODUCT_AREA]: item[ACCESSORS.PRODUCT_AREA],
      //   [ACCESSORS.SUB_AREA]: item[ACCESSORS.SUB_AREA],
      // }));

      const validationResponse = await validateData(auth0, {
        sfIds,
        oppIds,
        // domainsComponents
      });
      // console.log({ validationResponse });

      // if (validationResponse.domainsComponents) {
      //   validationResponse.domainsComponents.map((domainsComponent: any, index: number) => {
      //     const domain = domainsComponent[ACCESSORS.DOMAIN];
      //     const component = domainsComponent[ACCESSORS.PRODUCT_AREA];
      //     const subArea = domainsComponent[ACCESSORS.SUB_AREA];
      //
      //     //@ts-ignore
      //     domain?._id && handlers.setItemProp(index, 'domainId', domain?._id);
      //     //@ts-ignore
      //     component?._id && handlers.setItemProp(index, 'componentId', component?._id);
      //     //@ts-ignore
      //     subArea?._id && handlers.setItemProp(index, 'subComponentId', subArea?._id);
      //   });
      // }

      setValidateData((current: any) => ({
        ...current,
        ...validationResponse,
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error in extractAndFilterData:", error);
    }
  };

  useEffect(() => {
    extractAndValidateData();
  }, []);

  useEffect(() => {
    // console.log(erroredRows)
    data.forEach((row) => {
      validateRow(row, row.index);
    });
  }, [_validateData]);

  const attributes = Object.keys(data[0]).filter((k: string) =>
    k.startsWith("attrbt_")
  );

  const columnsArr: any[] = [
    {
      title: ACCESSORS.KEY,
      accessor: ACCESSORS.KEY,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.SFDC_ACCOUNT_ID,
      accessor: ACCESSORS.SFDC_ACCOUNT_ID,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.SFDC_OPPORTUNITY_ID,
      accessor: ACCESSORS.SFDC_OPPORTUNITY_ID,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.ORIGIN,
      accessor: ACCESSORS.ORIGIN,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.ORIGIN_ID,
      accessor: ACCESSORS.ORIGIN_ID,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.ORIGIN_LINK,
      accessor: ACCESSORS.ORIGIN_LINK,
    },
    {
      title: ACCESSORS.BUSINESS_CATEGORY,
      accessor: ACCESSORS.BUSINESS_CATEGORY,
      editable: {
        type: "select",
        data: BusinessCategories.map((s: any) => s.businessCategory),
      },
    },
    {
      title: ACCESSORS.BUSINESS_NAME,
      accessor: ACCESSORS.BUSINESS_NAME,
    },
    {
      title: ACCESSORS.TITLE,
      accessor: ACCESSORS.TITLE,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.DESCRIPTION,
      accessor: ACCESSORS.DESCRIPTION,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.CREATED_AT,
      accessor: ACCESSORS.CREATED_AT,
      editable: {
        type: "text",
      },
    },
  ];

  if (attributes.length > 0) {
    const filteredAttributes = attributes.map((k: string) => ({
      title: k,
      accessor: k,
    }));
    columnsArr.push(...filteredAttributes);
  }

  columnsArr.push({
    title: ACCESSORS.ERROR,
    accessor: ACCESSORS.ERROR,
    render: ({ ERROR }: any) => (
      <Text miw={150} lineClamp={1}>
        {ERROR}
      </Text>
    ),
  });

  const columns = columnsArr.map((col: DataTableColumn<any>) => ({
    ...col,
    render: (item: any) => (
      <TableCell
        // index={index}
        item={item}
        col={col}
        handlers={handlers}
        validateRow={validateRow}
        extraData={{ requestPriorities, ideaPriorities }}
        setValidateData={setValidateData}
      />
    ),
    cellsStyle: (record: any) =>
      record?.ERROR?.split(",").includes(col.accessor)
        ? { backgroundColor: "#feb9b9" }
        : undefined,
  }));

  console.log({columns})
  return (
    <DataTable<any>
      sx={{
        "thead tr:first-of-type": {
          borderColor: "#dee2e6",
          background: "#F3F4F6",
        },
        "thead th": {
          padding: "6px 10px !important",
        },
        "th[role='button'] svg.mantine-9cumsf": {
          display: "none",
        },
      }}
      totalRecords={data.length}
      columns={columns}
      records={data.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize
      )}
      fetching={loading}
      withColumnBorders
      recordsPerPage={pageSize}
      page={page}
      onPageChange={(p) => setPage(p)}
      recordsPerPageOptions={PAGE_SIZES}
      onRecordsPerPageChange={setPageSize}
      height={"80vh"}
    />
  );
}

export default CsvDataTable;
//.filter(row => row[ACCESSORS.ERROR]?.trim().length > 0)
