// const mapToOptions = (items: any[]) =>
//   items.reduce(
//     (acc: Record<string, string>, { _id, name }: { _id: string; name: string }) => {
//       acc[_id] = name;
//       return acc;
//     },
//     {}
//   );

// export const addOptionsToFilters = (
//   filtersConfig: { evidence: Record<string, any>; idea: Record<string, any> },
//   context: { [key: string]: any[] }
// ) => {
//   const updatedFiltersConfig = { ...filtersConfig };
//   // Iterate through both `evidence` and `idea` filters
//   //@ts-ignore
//   ['evidence', 'idea'].forEach((filterType: 'evidence' | 'idea') => {
//     updatedFiltersConfig[filterType] && Object.values(updatedFiltersConfig?.[filterType]).forEach((filter: any) => {
//       if (filter.optionsKeyAppContext && context[filter.optionsKeyAppContext]) {
//         const options = mapToOptions(context[filter.optionsKeyAppContext]);
//         console.log("addOptionsToFilters", {options, context})
//         console.log('addOptionsToFilters options', options, filter);
//         filter.options = options;
//       }
//     });
//   });

//   return updatedFiltersConfig;
// };

const mapToOptions = (items: any[]) =>
  items.reduce((acc: Record<string, string>, item: any) => {
    // Handle both object with `_id`/`name` and simple strings
    if (typeof item === "object" && item._id && item.name) {
      acc[item._id] = item.name;
    } else if (typeof item === "string") {
      acc[item] = item;
    }
    return acc;
  }, {});

export const addOptionsToFilters = (
  filtersConfig: { evidence: Record<string, any>; idea: Record<string, any> },
  context: { [key: string]: any[] }
) => {
  const updatedFiltersConfig = { ...filtersConfig };
  //@ts-ignore
  ["evidence", "idea"].forEach((filterType: "evidence" | "idea") => {
    if (updatedFiltersConfig[filterType]) {
      Object.values(updatedFiltersConfig[filterType]).forEach((filter: any) => {
        const optionsKey = filter.optionsKeyAppContext;

        // console.log("Checking filter:", { filterType, optionsKey, context });

        // Check if `optionsKey` exists in context and has items
        if (optionsKey && context[optionsKey]?.length) {
          // console.log("Found matching options:", {
          //   optionsKey,
          //   options: context[optionsKey],
          // });

          const options =
          ["components", "owners", "members"].includes(optionsKey)? 
            context[optionsKey].map((c:any) => ({
              ...c,
              label: c.name,
              value: c._id,
              childComponents: c?.childComponents?.map((cc:any) => ({
                ...cc,
                label: cc.name,
                value: cc._id,
              })),
            }))
            : mapToOptions(context[optionsKey]);
          // console.log("Prepared options:", options);

          filter.options = options;
        } else {
          // console.log("No relevant options found for:", optionsKey);
        }
      });
    }
  });

  return updatedFiltersConfig;
};

export const findFilterByName = (
  targetName: string,
  filterObject: "evidence" | "idea" | "all",
  ideasFiltersConfig: any
) => {
  const filtersList: any[] = [];

  if (filterObject == "all") {
    const evidenceFilters = ideasFiltersConfig["evidence"];
    const ideaFilters = ideasFiltersConfig["idea"];
    if (!evidenceFilters && !ideaFilters) return null;
    if (evidenceFilters) filtersList.push(evidenceFilters);
    if (ideaFilters) filtersList.push(ideaFilters);
  } else {
    const filters = ideasFiltersConfig[filterObject];
    if (!filters) return null;
    filtersList.push(filters);
  }

  const findRecursively = (obj: any): any => {
    for (const key in obj) {
      const filter = obj[key];
      if (filter.name?.toLowerCase() === targetName?.toLowerCase()) {
        const cleanedDbFieldPath = filter.dbFieldPath
          ?.replace("relatedRequests.", "")
          ?.replace("connectedOrgs", "company");
        return {
          ...filter,
          dbFieldPath: cleanedDbFieldPath,
        };
      }

      if (filter.childFilters) {
        const childResult = findRecursively(filter.childFilters);
        if (childResult) return childResult;
      }
    }
    return null;
  };

  for (const filters of filtersList) {
    const result = findRecursively(filters);
    if (result) return result;
  }

  return null;
};
