import React, { useContext } from 'react';
import { Filter } from '../FiltersMenu';
import FiltersContext from '../context/FiltersContext';
import OperationSelect from '../OperationSelect';
import AddFilterBtn from '../AddFilterBtn';
import { getFixedDateRanges } from '../FixedDateRanges';
import { Box } from '@mantine/core';
import { RangeCalendar } from '@mantine/dates';
import FilterFooter from '../FilterFooter';
import SyncContext from '../../../../context/SyncContext';

interface DateFilterProps {
  filters?: Filter;
  MenuType: "idea" | "evidence";
}

const DateFilter: React.FC<DateFilterProps> = ({
  MenuType
}) => {
  const {
    operator,
    setOperator,
    rangeDates,
    setRangeDates,
    childFiltersValue,
    selectedParentFilter,
    setActiveFilters,
    setOpenedMenuType,
    setSelectedParentFilter
  } = useContext(FiltersContext);
  const {ideasFiltersConfig } = useContext(SyncContext);


  const _selectedParentFilter: string[] = selectedParentFilter?.split('.') || [''];

  const selectedFilter = (_selectedParentFilter && _selectedParentFilter.length === 2)
  //@ts-ignore
    ? ideasFiltersConfig[MenuType][_selectedParentFilter[0]]?.ChildFilters[_selectedParentFilter[1]]
    : ideasFiltersConfig[MenuType][_selectedParentFilter[0]]

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;

  return (
    selectedParentFilter && <>
      {selectedHasOperations && (
        <OperationSelect
          operations={selectedFilter.operations}
          onChange={(value) => {
            typeof value == 'string' && setRangeDates((pre) => ({
              ...pre,
              [selectedParentFilter]: getFixedDateRanges[value].values,
            }))
            setOperator((prev: any) => ({
              ...prev,
              [selectedParentFilter]: value,
            }));
          }}
        />
      )}
      <Box py="sm" px={5}>
        <RangeCalendar
          fullWidth
          //@ts-ignore
          value={rangeDates?.[selectedParentFilter]?.map((i: string) => new Date(i)) || [null, null]}
          onChange={(value: [Date, Date]) =>
            setRangeDates((pre) => ({
              ...pre,
              [selectedParentFilter]: value,
            }))
          }
          styles={() => ({
            day: {
              "&[data-selected]": {
                backgroundColor: "#5B60E3",
              },
              "&[data-in-range]": {
                backgroundColor: "#5b60e321",
                "&[data-selected]": {
                  backgroundColor: "#5B60E3",
                },
              },
              "&[data-weekend]": {
                color: `#5B60E3`,
                "&[data-selected]": {
                  color: "#FFF",
                },
              },
            },
          })}
        />
      </Box>

      <FilterFooter
        ApplyButton={
          <AddFilterBtn
            BtnText="Apply"
            selectedParentFilter={selectedParentFilter}
            childFiltersValue={childFiltersValue}
            disabled={
              !rangeDates?.[selectedParentFilter] ||
                    !rangeDates?.[selectedParentFilter][0] ||
                    !rangeDates?.[selectedParentFilter][1]
            }
            onClick={() => {
              setActiveFilters((prevFilters: any) => [
                {
                  ...ideasFiltersConfig[MenuType][selectedParentFilter],
                  type: ideasFiltersConfig[MenuType][selectedParentFilter].type,
                  parent: selectedParentFilter,
                  operator: operator && operator[selectedParentFilter],
                  selected: rangeDates?.[selectedParentFilter],
                  query: null,
                },
                ...prevFilters.filter(
                  (prevF: any) => prevF.parent !== selectedParentFilter
                ),
              ]);
              setOpenedMenuType(null)
              setSelectedParentFilter(null)
              // resetFilters();
            }}
          />
        }
      />

      
    </>
  );
};

export default DateFilter;
