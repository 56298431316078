import { useAuth0 } from "@auth0/auth0-react";
import { Box, Group, Title } from "@mantine/core";
import {
  useDebouncedValue,
  useDisclosure,
  useElementSize,
} from "@mantine/hooks";
import { IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { canEditTeamIds } from "../../Api";
import {
  buildQueryString,
  getFilteredRequests as fetchFilteredRequests,
  starClicked as handleStarClicked,
  loadListMembers,
} from "./ideasHelpers";
import { RequestsTable } from "./RequestsTable";
import { getFixedDateRanges } from "./filters/FixedDateRanges";
import { FiltersSpec, noTrendFilterSpec } from "./filters/FiltersSpec";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";
import SyncContext from "../../context/SyncContext";
import Header from "./components/Header";
import FilterActions from "./components/FilterActions";
import IdeasContext, { IdeasContextProvider } from "./IdeasContext";
import FiltersContext, { FiltersContextProvider } from "./filters/context/FiltersContext";
import { useNavigate } from "react-router-dom";


const PAGE_SIZE = 50;

export const Ideas = () => {
  const { ref } = useElementSize();
  const { ref: warpRef, width: warpWidth } = useElementSize();
  const { ref: actionIconsRef, width: actionIconsWidth } = useElementSize();

  const { components, ideaStatuses, showDomain, domains, objectCustomizations, businessCategoryMapping, ideasFiltersConfig } =
    useContext(SyncContext);

  const {
    page,
    sortStatus,
    savedViews,
    selectedView,
    requestsTotalCount,
    setRequestsTotalCount,
    setPage,
    setSortStatus,
    setSelectedView,
    loadViews,
    upsertView,
  } = useContext(IdeasContext);

  const {
    activeFilters,
    setActiveFilters,
    checkedComponents,
    setCheckedComponents
  } = useContext(FiltersContext);
  // console.log({activeFilters, checkedComponents})
  const auth0 = useAuth0();
  const navigate = useNavigate();

  const [ideas, setIdeas] = useState<any[]>([]);
  const [loadingStar, setLoadingStar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 1000);

  const [searchOpened, setSearchOpened] = useState(false);
  const [isAllowedToEditDates, setIsAllowedToEditDates] = useState<boolean>();
  const [opened, { open, close: doClose }] = useDisclosure(false);
  const [viewName, setViewName] = useState("");
  const [menuFiltersObj, setMenuFiltersObj] = useState<any>(noTrendFilterSpec);
  const [, setLastFilterParams] = useState<any>({});

  // Prevent the effect from running on the first load
  // const [mounted, setMounted] = useState(false);
  const [filterRowOpened, { toggle }] = useDisclosure(true);

  const getFilteredRequests = () => {
    // console.log({ activeFilters });


    console.log(search)
    // if(search.length > 0){
    //   setSortStatus({ columnAccessor: "search", direction: "asc" })
    // }




    fetchFilteredRequests(
      sortStatus,
      setLastFilterParams,
      page,
      search,
      activeFilters,
      setLoading,
      setIdeas,
      setRequestsTotalCount, // Add this line
      setPage,
      ideasFiltersConfig,
      auth0
    );
  };

  const starClicked = (_id: string, bool: boolean) => {
    handleStarClicked(
      _id,
      bool,
      setLoadingStar,
      {
        id: _id,
        push: bool,
      },
      setIdeas,
      auth0
    );
  };

  const handleSaveViews = () => {
    upsertView({ name: viewName, filters: activeFilters }, (res) => {

      setSelectedView({
        _id: res?.data?.updateOneResult?.upsertedId,
        name: viewName,
        filters: activeFilters,
      });
      
      showNotification({
        title: "Saved successfully!",
        color: "teal",
        message: "view saved successfully",
        icon: <IconCheck size="1.1rem" />,
        autoClose: 1200
      });
      doClose();
    });
  };

  const loadCanEditTeamIds = () => {
    canEditTeamIds(auth0)
      .then((customizations: any) => {
        const name =
          customizations && customizations.length > 0
            ? customizations[0].name
            : undefined;
        if (name === "committedDate") {
          setIsAllowedToEditDates(true);
        } else {
          setIsAllowedToEditDates(false);
        }
      })

      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setMenuFiltersObj(ideasFiltersConfig)
  }, [ideasFiltersConfig]);

  // useEffect(() => {
  //   const componentsData: any = {};
  //   components &&
  //     components.map((component: any) => {
  //       return (componentsData[component.name] = component);
  //     });
  //
  //   const domainsData: any = {};
  //   showDomain &&
  //     domains.length &&
  //     domains.map((domain: any) => {
  //       return (domainsData[domain.name] = { ...domain, value: domain.name });
  //     });
  //
  //   const requestStatusesData: { [text: string]: string } = {};
  //   ideaStatuses &&
  //     ideaStatuses.sort().map((status: any) => {
  //       return (requestStatusesData[status.key] = status.key);
  //     });
  //
  //   setMenuFiltersObj((prev: any) => ({
  //     ...prev,
  //     "Product area": {
  //       ...prev["Product area"],
  //       options: components,
  //     },
  //     Status: {
  //       ...prev["Status"],
  //       options: requestStatusesData,
  //     },
  //     ...(showDomain
  //       ? {
  //         Domains: {
  //           ...prev["Domains"],
  //           options: domainsData || [],
  //           type: "checkbox",
  //         },
  //       }
  //       : {}),
  //
  //     Account:{
  //       ...prev["Account"],
  //       ChildFilters:{
  //         ...(objectCustomizations?.account?.fields?.plan?.options
  //           ? {
  //             plan: {
  //               ...prev?.Account?.ChildFilters?.plan,
  //               options: objectCustomizations.account.fields.plan.options.reduce((acc:any, option:any) => {
  //                 acc[option] = option;
  //                 return acc;
  //               }, {}),
  //               operations: [],
  //               type: "checkbox",
  //             }
  //           }
  //           : {}),
  //         ...(objectCustomizations?.account?.fields?.tier?.options
  //           ? {
  //             tier: {
  //               ...prev?.Account?.ChildFilters?.tier,
  //               options: objectCustomizations.account.fields.tier.options.reduce((acc:any, option:any) => {
  //                 acc[option] = option;
  //                 return acc;
  //               }, {}),
  //               operations: [],
  //               type: "checkbox",
  //             }
  //           }
  //           : {}),
  //         ...(objectCustomizations?.account?.accountTypesMap ?{
  //           accountTypesMap:{
  //             ...prev?.Account?.ChildFilters?.accountTypesMap,
  //             Label:"Account Type",
  //             options: objectCustomizations.account.accountTypesMap,
  //             operations:[],
  //             type: "checkbox",
  //           }} : {}),
  //
  //       }
  //     },
  //
  //     "Business Name":{
  //       ...prev["Business Name"],
  //       options: businessCategoryMapping,
  //     }
  //   }));
  //
  //   // getFilteredRequests();
  // }, [ideaStatuses, components, objectCustomizations, businessCategoryMapping]);


  useEffect(() => {
    getFilteredRequests();
  }, [activeFilters, page, sortStatus, debouncedSearch]);

  useEffect(() => {
    if (selectedView?.filters) {
      const _filters = selectedView?.filters.map((filter: any) =>
        filter.type == "date" && filter.operator
          ? {
            ...filter,
            selected: getFixedDateRanges[filter.operator].values,
            // name: selectedView.name,
          }
          : {
            ...filter,
            // name: selectedView.name,
          }
      );
      setActiveFilters(_filters);
    }
    setPage('1');
  }, [selectedView]);

  useEffect(() => {
    loadCanEditTeamIds();
    loadListMembers(setMenuFiltersObj, auth0);
    loadViews();
    const queryParams = new URLSearchParams(location.search);
    const filtersStr = queryParams.get("filters");
    const filters = filtersStr ? JSON.parse(decodeURIComponent(filtersStr)) : undefined;    
    if (filters) {
      setActiveFilters(filters);
      setCheckedComponents((prev) => ({
        ...prev,
        ...filters.reduce((acc:any, filter:any) => ({
          ...acc,
          [filter.parent.toLowerCase()]: filter?.selected?.map((i:string) => i.toLowerCase())
        }), {}),
      }));
    }
  }, []);


  useEffect(() => {
    const newQueryString = buildQueryString(page || "1", activeFilters, selectedView, sortStatus);
    navigate(`/?${newQueryString}`, { replace: true });
  }, [page, activeFilters, sortStatus, selectedView, navigate, buildQueryString]);
  return (
    <Box bg={"#fff"} ref={ref}>
      <Group m="md">
        <Title order={2}>Ideas</Title>
      </Group>

      {/* Header */}
      <Box ref={warpRef}>
        <Header
          savedViews={savedViews}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          upsertView={upsertView}
          warpWidth={warpWidth}
          actionIconsWidth={actionIconsWidth}
          toggle={toggle}
          search={search}
          setSearch={setSearch}
          searchOpened={searchOpened}
          setSearchOpened={setSearchOpened}
          actionIconsRef={actionIconsRef}
        />
      </Box>

      {/* Filter Actions */}
      {filterRowOpened && (
        
        <FilterActions
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          selectedView={selectedView}
          open={open}
          handleSaveViews={handleSaveViews}
          opened={opened}
          viewName={viewName}
          setViewName={setViewName}
          doClose={doClose}
        />
      )}

      {/* Main Table Display */}
      <Box sx={{ height: `calc(100vh - ${filterRowOpened ? 223 : 181}px)` }}>
        {sortStatus?.columnAccessor && (
          <RequestsTable
            requests={ideas}
            starClicked={starClicked}
            loadingStar={loadingStar}
            isAllowedToEditDates={isAllowedToEditDates || false}
            totalRecords={requestsTotalCount}
            recordsPerPage={PAGE_SIZE}
            handlePageChange={(newPage) => setPage(newPage)}
            page={(Number(page) || 1)}
            sortStatus={sortStatus}
            setSortStatus={setSortStatus}
            loading={loading}
            PAGE_SIZE={PAGE_SIZE}
          />
        )}
      </Box>
    </Box>
  );
};


export const IdeasWraper = () => (
  <IdeasContextProvider>
    <FiltersContextProvider>
      <Ideas/>
    </FiltersContextProvider>
  </IdeasContextProvider>
)