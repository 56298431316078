import { Box, Flex, Grid, ScrollArea, Stack, Text } from "@mantine/core";
import Header from "./idea-module/Header";
import Body from "./body/Body";
import SideBar from "./right-bar/evidence-card/SideBar";
import "../../css/fontsDesign.css";
import { useContext, useEffect, useState } from "react";
import IdeaContext from "./IdeaContext";
import "./idea.css";
import AddEvidenceScreen from "./right-bar/add-evidence/AddEvidenceScreen";
import { saveIdea, searchTextItemsNiv } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { stripHtml, useThrottle } from "../../utils";
import OverView from "./idea-module/OverView";
import { useElementSize } from "@mantine/hooks";
import DescriptionWidthMeasurment from "./DescriptionWidthMeasurment";
import TitleWidthMeasurment from "./TitleWidthMeasurment";
import MiniSearch from "minisearch";
import FiltersContext from "../ideas/filters/context/FiltersContext";
import { parseQueryParams } from "../idea";
import BusinessSources from "./right-bar/perspective/BusinessSources";
import { sortRequests } from "./helpers/sortRequests";
import Impact from "./impact";
import posthog from "posthog-js";
import TrendGraph from "./right-bar/trend-graph";
import SyncContext from "../../context/SyncContext";
import { getCalculatedPerspectiveForRequestNew } from "./helpers/aggregateRequestCompanyData";
import { findFilterByName } from "../../context/helpers";


export const FiltersKeyValMap: any = {
  "Business Name": {
    filter: "businessName",
    perspectiveDataType: "businessName",
    frontendFilter: "businessName",
  },
  "Account.tier": {
    filter: "tier",
    perspectiveDataType: "tier",
    frontendFilter: "company.tier",
  },
  "Account.plan": {
    filter: "plan",
    perspectiveDataType: "plan",
    frontendFilter: "company.plan",
  },
  "Account.accountTypesMap": {
    filter: "accountType",
    perspectiveDataType: "type",
    frontendFilter: "company.type",
  },
  "Company": {
    filter: "Company",
    perspectiveDataType: "Company",
    frontendFilter: "company._id",
  },
  "Quarter": {
    filter: "quarter",
    perspectiveDataType: "quarter",
    frontendFilter: "quarter",
  },
  "Week": {
    filter: "week",
    perspectiveDataType: "week",
    frontendFilter: "week",
  },
};

export const findKeyByPerspectiveDataType = (
  dataType: string
): any | undefined => {
  for (const key in FiltersKeyValMap) {
    if (FiltersKeyValMap[key].perspectiveDataType === dataType) {
      return { key, ...FiltersKeyValMap[key] };
    }
  }
  return dataType;
};

// Helper function to safely get nested properties
export const getNestedProperty = (object: any, path: any) => {
  return path
    .split(".")
    .reduce(
      (obj: any, key: any) =>
        obj && obj[key] !== undefined ? obj[key] : undefined,
      object
    );
};

const NewIdeaView = () => {
  const auth0 = useAuth0();
  const queryParams = parseQueryParams(location.search);
  const requestId = queryParams.get("requestId");

  const { ref: ref1, width: titleWidth } = useElementSize();
  const { ref: ref2, width: descWidth } = useElementSize();
  const {
    idea,
    allRequests: allRequestsContext,
    matches,
    displayedRequests,
    setDisplayedRequests,
    loadingEvidence,
    addEvidenceFormScreen,
    setAddEvidenceFormScreen,
    cardView,
    setCardView,
    chosenEvidence,
    setChosenEvidence,
    setDisplayedMatches,
    activeSort,
    ideaId,
    setMatchesTrigger,
    requestsMatches,
    setDisplayedRequestsMatches,
    setRequestsTrend,
    setMatchesTrend,
    setRequestsMatchesTrend,
    setPerspectiveData, 
    type,
    matchesLoading,
    showValidated, 
    showPending,
    showmatches, 
    activeInboxTab
  } = useContext(IdeaContext);
  const [filters, setFilters] = useState<any>(() => {
    const queryParams = new URLSearchParams(location.search);
    const filtersStr = queryParams.get("filters");
    return filtersStr ? JSON.parse(decodeURIComponent(filtersStr)) : null;
  });
  

  const { activeFilters } = useContext(FiltersContext);
  const { perspectiveConfigurations, ideasFiltersConfig } = useContext(SyncContext);
  
  const [activeSortOrFilter, setActiveSortOrFilter] = useState(false);
  const [index, setIndex] = useState<string | null>(null);
  const [reviewMode, setReviewMode] = useState(false);
  const [isDescendingSort, setIsDescendingSort] = useState(true);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchedEvidence, setSearchedEvidence] = useState<any[]>([]);
  const [textItemsResults, setTextItemsResults] = useState([]);
  const [query, setQuery] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [title, setTitle] = useState(idea?.title);
  const [description, setDescription] = useState(idea?.description);
  const [isLongDescription, setIsLongDescription] = useState(false);
  const [isLongTitle, setIsLongTitle] = useState(false);

  const miniSearch = new MiniSearch({
    fields: ["title", "text", "title1", "text1"], // fields to index for full-text search
    // idField: '_id',
    searchOptions: {
      fuzzy: 0.2,
      prefix: true,
    },
  });
  miniSearch.removeAll();

  const allRequests = [...allRequestsContext, ...matches, ...requestsMatches];
  const aligned = allRequests?.map((m: any) => {
    return {
      id: m._id,
      title: m?.title,
      text: m?.text,
      title1: m?.textItem?.title,
      text1: m?.textItem?.text,
    };
  });
  miniSearch.addAll(aligned);

  const searchTextItems = () => {
    if (query === "") {
      setTextItemsResults([]);
      return;
    }
    searchTextItemsNiv(query, auth0)
      .then((res) => {
        // console.log(res);
        setTextItemsResults(res);
      })
      .catch((e) => console.log(e));
  };

  const searchEvidence = (text: string) => {
    setQuery(text);

    if (text === "") {
      setActiveSearch(false);
      setTextItemsResults([]);
    } else {
      const results: any[] = miniSearch.search(text);

      const resIds = results.map((m: any) => m.id);
      const matchedRequests = allRequests.filter((m: any) => {
        return resIds.includes(m._id);
      });

      setActiveSearch(true);
      setSearchedEvidence(matchedRequests);
      setTriggerSearch(!triggerSearch);
    }
  };

  const chooseMatch = (id: string, isFromSearch: boolean) => {
    /* console.log(id); */
    setAddEvidenceFormScreen(false);
    setCardView(true);

    let found = {};

    if (isFromSearch) {
      found = textItemsResults?.find((m: any) => m._id === id) || {};
      found = { ...found, status: "search" };
    } else {
      found =
        allRequestsContext?.find((m: any) => m._id === id) ||
        matches?.find((m: any) => m._id === id) ||
        requestsMatches?.find((m: any) => m._id === id) ||
        {};
    }

    setChosenEvidence(found);
    setIndex(id);
  };

  useEffect(() => {
    if (allRequests.length > 0 && requestId && requestId !== "" && chosenEvidence == null) {
      chooseMatch(requestId, false);
    }
  }, [allRequests]);

  const filterRequests = (requests: any[], filters: any[]) => {
    // Check if filters are present and if any filter has selected items
    if (
      !filters.length ||
      filters.every(
        (filter) => !filter.selected || filter.selected.length === 0
      )
    ) {
      return requests; // Return all requests if no filters are active or selected arrays are empty
    }

    return requests.filter((request) => {
      return filters.every((filter) => {
        const { parent, selected, name } = filter;
        if (!selected || selected.length === 0) return true; // Skip filtering for this filter if no selections

        const CurrentFilterConfig = findFilterByName(name, 'evidence', ideasFiltersConfig)

        // const filterPath = FiltersKeyValMap[parent]?.frontendFilter;
        const filterPath = CurrentFilterConfig?.dbFieldPath 
        // || FiltersKeyValMap[parent]?.frontendFilter;

        if (!filterPath) return true; // Skip this filter if path is not defined

        const requestValue = getNestedProperty(request, filterPath);

        
        if (Array.isArray(requestValue)) {
          return requestValue
            .map((rV) => rV.toLowerCase())
            .some((r) => selected.includes(r.toLowerCase()));
        } else {
          return selected.includes(requestValue?.toLowerCase());
        }
      });
    });
  };

  const updateData = () => {
    if (allRequestsContext.length > 0 && requestId && requestId !== "") {
      chooseMatch(requestId, false);
    }
  
    const filteredRequests = filterRequests(allRequestsContext, activeFilters);
    const filteredMatches = filterRequests(matches, activeFilters);
    const filteredRequestsMatches = filterRequests(requestsMatches, activeFilters);
  
    setDisplayedRequests(filteredRequests);
    setDisplayedMatches(filteredMatches);
    setDisplayedRequestsMatches(filteredRequestsMatches);
  
    const noTrendFilters = activeFilters.filter(
      (filter) => filter.name !== "quarter" && filter.name !== "week"
    );
  
    const filteredRequestsTrend = filterRequests(allRequestsContext, noTrendFilters);
    const filteredMatchesTrend = filterRequests(matches, noTrendFilters);
    const filteredRequestsMatchesTrend = filterRequests(requestsMatches, noTrendFilters);
  
    setRequestsTrend(filteredRequestsTrend);
    setMatchesTrend(filteredMatchesTrend);
    setRequestsMatchesTrend(filteredRequestsMatchesTrend);
  
    const CurrentFilterConfig = findFilterByName(type, "evidence", ideasFiltersConfig);
  
    const noSelectedPerspectiveFilters = activeFilters.filter(
      (filter) =>
        filter.parent !== CurrentFilterConfig?.name &&
        CurrentFilterConfig?.filterObject !== "evidence"
    );
  
    const matchesMapped = matches.map((m) => ({
      ...m,
      status: "match",
      source: "suggestion",
    }));
    const requestsMatchesMapped = requestsMatches.map((m) => ({
      ...m,
      status: "match",
      source: "suggestion",
    }));
  
    const allRequestsMapped = allRequestsContext.map((a, index) => ({
      ...a,
      businessName: a?.textItem?.businessName || a?.businessName || "N/A",
      sortInit: index,
    }));
  
    const filteredRequestsPerspective = filterRequests(
      allRequestsMapped,
      noSelectedPerspectiveFilters
    );
    const filteredMatchesPerspective = filterRequests(
      matchesMapped,
      noSelectedPerspectiveFilters
    );
    const filteredRequestsMatchesPerspective = filterRequests(
      requestsMatchesMapped,
      noSelectedPerspectiveFilters
    );
  
    const requestsToCalculate = [
      ...filteredMatchesPerspective,
      ...filteredRequestsMatchesPerspective,
      ...filteredRequestsPerspective,
    ];
  
    getCalculatedPerspectiveForRequestNew(
      requestsToCalculate,
      perspectiveConfigurations
    )
      .then((data) => {
        setPerspectiveData((prev) => ({
          ...prev,
          ...data,
        }));
      })
      .catch((error) => {
        console.error("Error recalculating company data:", error);
      });
  };

  useEffect(() => {
    updateData();
  }, [activeFilters, ideaId, type, allRequestsContext, matches, requestsMatches]);


  useEffect(() => {
    if (activeSort) {
      setDisplayedRequests(prev => sortRequests(prev, activeSort));
      setDisplayedMatches(prev => sortRequests(prev, activeSort));
      setDisplayedRequestsMatches(prev => sortRequests(prev, activeSort));
    }
  }, [activeSort, ideaId]);

  const throttledSearch = useThrottle(() => searchTextItems(), 1000);

  const updateIdeaDescription = (text: string) => {
    if (idea && idea?.description !== text) {
      const ideaObj = {
        id: idea._id,
        description: text,
      };
      saveIdea(ideaObj, auth0)
        .then(() => {
          
          setMatchesTrigger(0)
        })
        .catch((e: any) => console.log(e));
    }
  };

  const longDescriptionCheck = () => {
    if (descWidth > 600) {
      setIsLongDescription(true);
    } else {
      setIsLongDescription(false);
    }
  };

  const longTitleCheck = () => {
    if (titleWidth > 520) {
      setIsLongTitle(true);
    } else {
      setIsLongTitle(false);
    }
  };

  const navToPerspective = () => {
    setCardView(false);
    setAddEvidenceFormScreen(false);
    setChosenEvidence(null)
  };

  useEffect(() => {
    query !== "" && throttledSearch();
  }, [triggerSearch]);

  useEffect(() => {
    setDescription(stripHtml(idea?.description || "") || "");
    setTitle(idea?.title);
    longDescriptionCheck();
    longTitleCheck();
  }, [idea, ideaId]);



  return (
    <Box>
      <Box
        h={reviewMode ? "44px" : "74px"}
        px="16px"
        pt="7px"
        pb="10px"
        bg="#F8F8FB"
        sx={{
          borderBottom: "1px solid #D8D8DB",
        }}
      >
        <Header
          reviewMode={reviewMode}
          setReviewMode={setReviewMode}
          description={description}
          title={title}
          setTitle={setTitle}
          isLongTitle={isLongTitle}
          longTitleCheck={longTitleCheck}
          descWidth={descWidth}
          titleWidth={titleWidth}
          isLongDescription={isLongDescription}
        />
      </Box>
      <Grid
        gutter={0}
        h={`calc(100vh - ${reviewMode ? 107 : 137}px)`}
        sx={{
          overflowY: "scroll",
        }}
        columns={120}
      >
        <Grid.Col
          span={72}
          bg="#F8F8FB"
          sx={{
            borderRight: "1px solid #D8D8DB",
            borderBottom: "1px solid #D8D8DB",
          }}
        >
          {reviewMode && (
            <OverView
              reviewMode={reviewMode}
              setReviewMode={setReviewMode}
              description={description}
              setDescription={setDescription}
              updateIdeaDescription={updateIdeaDescription}
              longDescriptionCheck={longDescriptionCheck}
            />
          )}
          <Body
            chooseMatch={chooseMatch}
            addEvidenceFormScreen={addEvidenceFormScreen}
            setAddEvidenceFormScreen={setAddEvidenceFormScreen}
            setCardView={setCardView}
            activeSortOrFilter={activeSortOrFilter}
            displayedRequests={displayedRequests}
            activeSearch={activeSearch}
            searchEvidence={searchEvidence}
            searchedEvidence={searchedEvidence}
            query={query}
            textItemsResults={textItemsResults}
            sortDescending={isDescendingSort}
            chosenEvidence={chosenEvidence}
          />
        </Grid.Col>
        <Grid.Col bg="#FDFEFF" span={48}>
          {cardView ? (
            <SideBar
              reviewMode={reviewMode}
              skipMatch={console}
              index={index}
              cardView={cardView}
              setCardView={setCardView}
              navToPerspective={navToPerspective}
              setActiveSearch={setActiveSearch}
            />
          ) : addEvidenceFormScreen ? (
            <AddEvidenceScreen
              addEvidenceFormScreen={addEvidenceFormScreen}
              setAddEvidenceFormScreen={setAddEvidenceFormScreen}
              navToPerspective={navToPerspective}
              reviewMode={reviewMode}
            />
          ) : loadingEvidence ? (
            <Box w="496px"></Box>
          ) : !loadingEvidence && allRequests.length === 0 ? (
            <Flex justify="center" p={30} w="496px">
              <Stack
                px={50}
                py={25}
                mt={36}
                w="360px"
                h="295px"
                bg="rgba(92, 92, 235, 0.1)"
                sx={{ borderRadius: "8px" }}
              >
                <Box>
                  <Text fz={13} fw={600}>
                    There are no Evidences for this Idea yet.
                  </Text>
                  <Text fz={13}>
                    You can enrich the Idea with Evidences in 3 ways:
                  </Text>
                </Box>

                <Text fz={13}>
                  • Edit the Idea’s description to find matching evidences
                  suggested by AI
                </Text>
                <Text fz={13}>
                  • Search for existing evidences based on keyword
                </Text>
                <Text
                  fz={13}
                  color="#5C5CEB"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAddEvidenceFormScreen(true)
                    posthog.capture('add_evidence_clicked', {
                      ideaId: idea._id,
                      type: 'manually'
                    })
                  }}
                >
                  • Add Evidences manually
                </Text>
              </Stack>
            </Flex>
          ) : (
            <ScrollArea h={`calc(100vh - 137px)`} mah={`calc(100vh - 137px)`} /* px={24} */>
              <Impact/>
              <Box ml={18} /* w='100%' */ mr={-42}>
                <TrendGraph/> 
              </Box>
              <BusinessSources />
              
               
            </ScrollArea>
            
          )}
        </Grid.Col>
      </Grid>
      {/* Elements for size measurement */}

      <Flex
        p="0px"
        m="0px"
        mah="0px"
        h="0px"
        ref={ref2}
        sx={{
          transform: "scale(0)",
          position: "absolute",
          zIndex: 2,
        }}
      >
        <DescriptionWidthMeasurment description={description} longDescriptionCheck={longDescriptionCheck}/>
      </Flex>
      <Flex
        p="0px"
        m="0px"
        mah="0px"
        h="0px"
        ref={ref1}
        sx={{
          transform: "scale(0)",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <TitleWidthMeasurment title={title} longTitleCheck={longTitleCheck}/>
      </Flex>
    </Box>
  );
};

export default NewIdeaView;
