import {
  Autocomplete,
  Avatar,
  Button,
  Flex,
  Group,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { IconGripVertical, IconPlus } from "@tabler/icons";
import React, { forwardRef, useState } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { useForm } from "@mantine/form";
import { useAuth0 } from "@auth0/auth0-react";
import { removeIndentation, upsertComponent } from "../../../Api";
import { showNotification } from "@mantine/notifications";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  picture: string;
  name: string;
  email: string;
}

interface DomainItemProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string;
  value: string;
}

function RowItem({
  element,
  isChild,
  members,
  isFirst,
  setRemovedRow,
  elements,
  index,
  domains,
  handleRemoveValue,
  parent,
  loadPage,
  setLoadPage,
}: {
  element: any;
  isChild: boolean;
  members: any;
  isFirst?: boolean;
  setRemovedRow: any;
  elements: any;
  index: number;
  domains: any;
  handleRemoveValue: (id: string)=> void
  parent?: any
  loadPage: boolean;
  setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const auth0 = useAuth0();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [childItem, setChildItem] = useState(isChild);
  const [clickAble, setClickable] = useState(true)
  const form = useForm({
    initialValues: element,
  });
  const oldName = element.name

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const handleEdit = (id: string) => {
    setOnEdit(true);
    form.setValues(element);
  };

  const sendSavedData = async (updateData: any, componentId: string) => {
    try {
      const body = { updateData, componentId, oldName };
      await upsertComponent(auth0, body).then((res) => {
        saveNotification();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const updateOwner = (
    formValues: any,
    elementId: string,
    ownerName: string,
    ownerId: string
  ) => {
    formValues.ownerName = ownerName;
    formValues.ownerId = ownerId;

    sendSavedData(formValues, elementId);
  };

  const updateDomain = (
    formValues: any,
    elementId: string,
    domainName: string
  ) => {
    formValues.domain = domainName;
    sendSavedData(formValues, elementId);
  };

  const handleSave = (id: string, formValues: any) => {
    element.name = formValues.name;
    element.value = formValues.value;
    element.ownerName = formValues.ownerName;
    element.ownerId = formValues.ownerId;

    setOnEdit(false);
    sendSavedData(formValues, id);
  };

  const handleRemoveIndentation = async (
    componentId: string,
    child: boolean,
    parentId: string
  ) => {
    try {
      const body = { componentId, child, parentId };
      await removeIndentation(auth0, body).then((res) => {
        console.log(res);
        setLoadPage(!loadPage)
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleIndentation = (child: boolean, id: string) => {
    setChildItem(!childItem);
    if (childItem === true) {
      handleRemoveIndentation(id, child, "");
    } else {
      handleRemoveIndentation(id, child, parent?._id);   
    }
    setClickable(!clickAble)
  };

  const removeRow = (formValues: any, id: string) => {
    formValues.delete = true;
    sendSavedData(formValues, id);
    setRemovedRow(true);
  };

  const handleRemove = (id: string) => {
    handleRemoveValue(id)
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ picture, name, email, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <Avatar src={picture} radius="xl" />

            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {email}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  const DomainItem = forwardRef<HTMLDivElement, DomainItemProps>(
    ({ name, value, ...others }: DomainItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {value}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  return (
    <tr key={element._id}>
      <td
        style={{
          width: "30px",
          textAlign: "left",
          paddingLeft: "30px",
          paddingRight: 0,
        }}
      >
        {childItem === true ? (
          <></>
        ) : (
          <>
            {isFirst ? (
              <IconGripVertical size="1.05rem" stroke={1.5} />
            ) : (
              <>
                <IconGripVertical size="1.05rem" stroke={1.5} />
                <Tooltip label="Convert to Product sub-area" withArrow>
                  <UnstyledButton
                    disabled={!clickAble}
                    p={0}
                    m={0}
                    onClick={() =>{
                      setClickable(!clickAble)
                      handleToggleIndentation(childItem, element._id)
                    }
                      
                    }
                  >
                    <SourceIcon sourceName={"arrow-right"} />
                  </UnstyledButton>
                </Tooltip>
              </>
            )}
          </>
        )}
      </td>
      <td
        width={"150px"}
        style={{
          textAlign: childItem === true ? "center" : "left",
        }}
        {...form.getInputProps("name")}
      >
        {childItem === true ? (
          <Group>
            <Flex>
              <IconGripVertical size="1.05rem" stroke={1.5} />
              <Tooltip label="Convert back to Product Area" withArrow>
                <UnstyledButton
                  py={0}
                  pl={5}
                  m={0}
                  onClick={() =>
                    handleToggleIndentation(childItem, element._id)
                  }
                >
                  <SourceIcon sourceName={"arrow-left"} />
                </UnstyledButton>
              </Tooltip>
            </Flex>
            <>
              {onEdit ? (
                <TextInput {...form.getInputProps("name")} />
              ) : (
                element.name
              )}
            </>
          </Group>
        ) : (
          <>
            {onEdit ? (
              <TextInput {...form.getInputProps("name")} />
            ) : (
              element.name
            )}
          </>
        )}
      </td>
      <td width={"150px"} style={{ textAlign: "left" }}>
        {onEdit ? (
          <TextInput
            {...form.getInputProps("value")}
            placeholder="Add description here..."
          />
        ) : element.value.length > 0 ? (
          element.value
        ) : (
          <Text c="dimmed">No description yet...</Text>
        )}
      </td>
      <td width={"150px"}>
        {members && members.length > 0 && (
          <Autocomplete
            placeholder="Select owner"
            itemComponent={SelectItem}
            value={form.values.ownerName || ""}
            onChange={(val) => {
              form.setFieldValue("ownerName", val);
            }}
            onItemSubmit={(item) => {
              form.setFieldValue("ownerId", item._id);
              form.setFieldValue("ownerName", item.name);
              updateOwner(form.values, element._id, item.name, item._id);
            }}
            data={members}
            nothingFound={
              <Button fullWidth disabled leftIcon={<IconPlus size={18} />}>
                Invite member
              </Button>
            }
            filter={(value, user) =>
              (user.name &&
                user.name.toLowerCase().includes(value.toLowerCase().trim())) ||
              (user.email &&
                user.email.toLowerCase().includes(value.toLowerCase().trim()))
            }
            error={form.errors.ownerName || form.errors.ownerId}
          />
        )}
      </td>
      <td width={"150px"}>
        {domains && domains.length > 0 && (
          <Autocomplete
            placeholder="Select domain"
            itemComponent={DomainItem}
            value={form.values.domain || ""}
            onChange={(val) => {
              form.setFieldValue("domain", val);
            }}
            onItemSubmit={(item) => {
              form.setFieldValue("domain", item.name);
              updateDomain(form.values, element._id, item.name);
            }}
            data={domains}
            nothingFound={<Text>No domains were found...</Text>}
            filter={(value, item) =>
              (item.name &&
                item.name.toLowerCase().includes(value.toLowerCase().trim())) ||
              (item.value &&
                item.value.toLowerCase().includes(value.toLowerCase().trim()))
            }
            error={form.errors.ownerName || form.errors.ownerId}
          />
        )}
      </td>

      <td width={"60px"}>
        {onEdit ? (
          <Button
            variant="subtle"
            color="dark"
            aria-label="Save"
            onClick={() => handleSave(element._id, form.values)}
            disabled={form.values.name.length > 0 ? false : true}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="subtle"
            color="dark"
            aria-label="Edit"
            onClick={() => handleEdit(element._id)}
          >
            Edit
          </Button>
        )}

        <Button
          variant="subtle"
          color="dark"
          leftIcon={<SourceIcon sourceName={"bin"} />}
          aria-label="Remove"
          onClick={() => handleRemove(element._id)}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

export default RowItem;
