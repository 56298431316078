import { Stack } from "@mantine/core";
import SupportTicket from "../../../ideaNiv/right-bar/evidence-card/SupportTicket";
import BodyHeader from "./BodyHeader";
import CardAttributes from "./CardAttributes";
import CardTextArea from "./CardTextArea";
import GongCall from "./GongCall";

const CardBody = ({ item }: { item: any }) => {
  const origin = item?.origin || item?.textItem?.origin || item?.chat?.origin;
  const isGongCall = origin === "gong";
  const cxItem = item?.chat;
  const isSupportTicket = ["zendesk","intercom"].includes(origin) && cxItem?.itemType === "conversation";

  return (
    <Stack>
      <BodyHeader item={item} />
      <CardAttributes item={item} />
      {isSupportTicket ? (
        <SupportTicket item={item} />
      ) : isGongCall ? (
        <GongCall item={item} />
      ) : (
        <CardTextArea item={item} />
      )}
    </Stack>
  );
};

export default CardBody;
