import { Menu, Box } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconDotsVertical, IconPencil, IconTrash } from "@tabler/icons";
import { useState } from "react";

const TabMenu = ({
  item,
  hovered,
  setOnEdit,
  upsertViews,
}: {
  item: any;
  hovered: boolean;
  setOnEdit: React.Dispatch<React.SetStateAction<boolean>>;
  upsertViews: (body: any, func: (res: any) => void) => void;
}) => {
  const [opened, setOpened] = useState(false);
  return ((hovered || opened) && !item?.isGlobal) ? (
    <Menu
      styles={{
        dropdown: {
          marginTop: -4,
        },
      }}
      shadow="md"
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <Box pl={5} pt={4} onClick={() => setOpened((prev: boolean) => !prev)}>
          <IconDotsVertical size={13} />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={<IconPencil size={14} />}
          onClick={() => setOnEdit(true)}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          color="red"
          icon={<IconTrash size={14} />}
          onClick={() => {
            upsertViews({ _id: item._id, deleted: true }, (res) =>{
              console.log(res)
              showNotification({
                title: "deleted successfully!",
                color:"teal",
                message: "view deleted successfully",
                icon:<IconTrash size="1.1rem" />,
                autoClose: 1200
              })}
            );
          }}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  ) : (
    <></>
  );
};

export default TabMenu;
