import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Group,
  Select,
  Textarea,
  Title,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals,  openConfirmModal } from "@mantine/modals";
import {  useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteIdea, dismissFeedback } from "../../../../../Api";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import IdeaContext from "../../../IdeaContext";

export function DeclineModal({
  request,
  idea,
  navToPerspective,
  loadEvidence
 
}: {
  request: any;
  idea: any;
  navToPerspective: any
  loadEvidence: any
 
}) {
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { reCalculatPerspectiveForRequest } = useContext(IdeaContext);

  // const [comment, setComment] = useState('')
  // const [reason, setReason] = useState<string | null>(null)
  const form = useForm({
    initialValues: {
      reason: "",
      comment: "",
    },
  });
  const submitterName = request.internalSubmitter?.name || request.cxSubmitter?.name || request.submitter?.displayName

  const handleDecline = (values: any) => {
    /* console.log({values}) */
    setLoading(true);
    dismissFeedback(
      request._id,
      { reason: values.reason, comment: values.comment },
      auth0
    )
      .then((result) => {
        showNotification({
          title: "Evidence has been declined",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        loadEvidence() 
        closeAllModals();
        navToPerspective()

        reCalculatPerspectiveForRequest(request, 'remove');
        /* console.log({dismissFeedbackresult: result}) */
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        
        setLoading(false);
        if (idea?.requests?.length < 2 && idea?.origin !== "clickup" && idea?.origin !== "jira") {
          openDeleteRequestPopup();
        }
      });
  };

  const openDeleteRequestPopup = () =>
    openConfirmModal({
      title: "Delete idea",
      centered: true,
      children: (
        <Text size="sm">
          This idea has no evidence. do you want to delete it?
        </Text>
      ),
      labels: { confirm: "Delete idea", cancel: "No, keep it for now" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteIdea(idea._id, auth0)
          .then((response) => {
            navigate("/ideas");
          })
          .catch((e: any) => console.log(e));
      },
    });

  return (
    <Box>
      <Box>
        <Title color="#5B60E3" order={3} align="center">
          { submitterName ? `Let ${submitterName} know why this evidence was declined` : "Let stakeholders know why this evidence was declined"}
        </Title>
      </Box>
      <Box m="xl" sx={{ width: 400 }} mx="auto">
        <form onSubmit={form.onSubmit((values) => handleDecline(values))}>
          <Select
            mb="md"
            label="Reason"
            placeholder="Select reason"
            withAsterisk
            data={[
              "Solution existing (feature / alternative / work around)",
              "Not a product request",
              "Not aligned with product vision",
              "Low demand/low ROI",
              "Other",
            ]}
            {...form.getInputProps("reason")}
          />
          <Textarea
            withAsterisk
            placeholder="Add your comment"
            label="Add comment"
            {...form.getInputProps("comment")}
          />

          <Group position="center" mt="xl">
            <Button
              disabled={form.values.comment.length < 3 || form.values.reason === null}
              loading={loading}
              mt="xl"
              sx={{
                backgroundColor: "#5B60E3",
                ":hover": {
                  backgroundColor: "#4c50ba",
                },
              }}
              type="submit"
              size="lg"
            >
              Decline
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  );
}
