// import {
//   Badge,
//   ActionIcon,
//   Tooltip,
//   Text,
//   HoverCard,
//   Stack,
//   ScrollArea,
//   Sx,
// } from "@mantine/core";
// import { IconCircleX, IconX } from "@tabler/icons";
// import moment from "moment";
// import { getFixedDateRanges } from "./FixedDateRanges";
// import { useContext } from "react";
// import SyncContext from "../../../context/SyncContext";
// import { evidenceParents } from "./FiltersSpec";
// import FiltersContext from "./context/FiltersContext";
// import { OverflownText } from "../../OverflownText";

// interface FilterBadgeProps {
//   filter: any;
//   ownerOptions: { [key: string]: any };
//   onRemoveClick: () => void;
//   selectedView?: any;
//   MenuType: "idea" | "evidence";
//   sx?: Sx | (Sx | undefined);
// }

// const FilterBadge: React.FC<FilterBadgeProps> = ({
//   filter,
//   ownerOptions,
//   onRemoveClick,
//   selectedView,
//   MenuType,
//   sx,
// }) => {
//   const { allComponents, ideasFiltersConfig } = useContext(SyncContext);
//   console.log({ideasFiltersConfig, FilterBadgeProps:{
//     filter,
//     ownerOptions,
//     selectedView,
//     MenuType,
//   }})
//   const { setOpenedMenuType, setSelectedParentFilter } =
//     useContext(FiltersContext);

//   let filterText = filter.parent.includes(".")
//     ? filter.parent.split(".")[1]
//     : filter.parent;
//   let selected = filter?.selected || [];

//   const handleBadgeClick = () => {
//     // Set the menu type based on which section the parent belongs to
//     setOpenedMenuType(MenuType);
//     // Set the selected parent filter
//     setSelectedParentFilter(filter.parent);
//     // Optional: Log the selected parent filter for debugging
//     console.log({ parent: filter.parent });
//   };

//   if (filter.parent === "Product area") {
//     const namesArray: any[] = [];
//     selected.forEach((s: any) => {
//       const found = allComponents.find(
//         (a: any) => a._id?.toString() === s?.toString()
//       );
//       namesArray.push(found?.name);
//     });
//     selected = namesArray;
//   }
//   // console.log({filter, selected})
//   const safeSelected = Array.isArray(selected) ? selected : [];
//   const copy = [...safeSelected];
//   const sliced = copy.slice(0, 3);
 

//   if (filter.type === "date") {
//     filterText += filter.operator
//       ? " " + getFixedDateRanges[filter.operator].lable
//       : ` ${selected
//         .map((date: any) => moment(new Date(date)).format("MMM Do YY"))
//         .join(" - ")}`;
//   } else if (filter.type === "checkbox") {
//     if (safeSelected.length === 0) {
//       return null;
//     }
//     if (filter.parent.toLowerCase() === "product area") {
//       filterText += ` ${filter.operator ? filter.operator : "-"} ${
//         ["owner", "mentions"].includes(filter.parent.toLowerCase())
//           ? sliced === undefined
//             ? "unassigned"
//             : sliced
//               .map((selected: string) => ownerOptions[selected])
//               .join(", ")
//           : sliced.join(", ") + "..."
//       }`;
//     } else {
//       filterText += ` ${filter.operator ? filter.operator : "-"} ${
//         ["owner", "mentions"].includes(filter.parent.toLowerCase())
//           ? selected === undefined
//             ? "unassigned"
//             : selected.map((s: string) => ownerOptions[s]).join(", ")
//           : selected.join(", ")
//       }`;
//     }
//   } else if (filter.type === "fetchableCheckbox") {
//     filterText = `${filter.parent} (${selected.length} selected)`;
//   } else if (filter.type === "number") {
//     filterText += ` ${filter.operator.replace("_", " ")} ${
//       filter.query && filter.query
//     }`;
//   } else if (filter.type === "boolean") {
//     filterText += ` - ${
//       typeof filter.operator === "boolean" && filter.operator ? "Yes" : "No"
//     }`;
//   }

//   let removeble = true;
//   if (selectedView?.isGlobal) {
//     selectedView.filters.forEach((selectedFilter: any) => {
//       if (
//         JSON.stringify({
//           ...selectedFilter,
//           name: selectedView.name,
//         }).toLowerCase() === JSON.stringify(filter).toLowerCase()
//       ) {
//         removeble = false;
//       }
//     });
//   }
 
//   return (
//     <Badge
//       onClick={handleBadgeClick}
//       size="lg"
//       radius="sm"
//       variant="outline"
//       styles={{
//         root: {
//           backgroundColor: "#EFEFFD",
//           borderColor: "#EFEFFD",
//           color: "black",
//           fontSize: 12,
//           fontWeight: 400,
//           height: 32,
//           cursor: "pointer",
//         },
//       }}
//       sx={{ paddingRight: removeble ? 5 : 9, fontSize: 10, ...sx }}
//       rightSection={
//         removeble ? (
//           <Tooltip
//             sx={{ fontSize: 10 }}
//             withArrow
//             label="Remove filter"
//             position="bottom"
//           >
//             <ActionIcon
//               onClick={(event) => {
//                 event.stopPropagation(); // Prevents the click from bubbling to the Badge
//                 onRemoveClick(); // Call the specific remove function
//               }}
//               size="xs"
//               color="blue"
//               radius="xl"
//               variant="transparent"
//             >
//               <IconX size={15} color="#5C5CEB99" />
//             </ActionIcon>
//           </Tooltip>
//         ) : (
//           <></>
//         )
//       }
//     >
//       <OverflownText
//         maw={"200px"}
//         sx={{
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {filterText}
//       </OverflownText>
//     </Badge>
//   );
// };
// export default FilterBadge;
import {
  Badge,
  ActionIcon,
  Tooltip,
  Sx,
} from "@mantine/core";
import { IconX } from "@tabler/icons";
import moment from "moment";
import { getFixedDateRanges } from "./FixedDateRanges";
import { useContext } from "react";
import SyncContext from "../../../context/SyncContext";
import FiltersContext from "./context/FiltersContext";
import { OverflownText } from "../../OverflownText";
import { findFilterByName } from "../../../context/helpers";

interface FilterBadgeProps {
  filter: any;
  onRemoveClick: () => void;
  selectedView?: any;
  MenuType: "idea" | "evidence";
  sx?: Sx | (Sx | undefined);
}

const FilterBadge: React.FC<FilterBadgeProps> = ({
  filter,
  onRemoveClick,
  selectedView,
  MenuType,
  sx,
}) => {
  // console.log("FilterBadge", { filter });
  const { ideasFiltersConfig } = useContext(SyncContext);
  const { setOpenedMenuType, setSelectedParentFilter } =
    useContext(FiltersContext);

  const selected = filter?.selected || [];

  const handleBadgeClick = () => {
    setOpenedMenuType(MenuType);
    setSelectedParentFilter(filter.parent);
    // console.log("handleBadgeClick",{MenuType, filter})
  };

  // Get filterConfig from ideasFiltersConfig
  const filterConfig = findFilterByName(!filter.isView ? filter.name  || filter.parent?.split(".").pop().toLowerCase() : filter.parent, MenuType, ideasFiltersConfig);
  const filterType = filterConfig?.type;
  const options = filterConfig?.options;
  let filterText = filterConfig.label;

  // console.log("FilterBadge",{filter, filterConfig, filterType, options})

  const safeSelected = Array.isArray(selected) ? selected : [];

  if (filterType === "date") {
    filterText += ` From ${selected
      .map((date: any) => moment(new Date(date)).format("MM/DD/YY"))
      .join(" To ")}`;
  }else if (filterType === "checkbox" || filterType === "fetchableCheckbox") {
    if (safeSelected.length === 0) {
      return null;
    }
    if (options && Object.keys(options).length > 0) {
      let displayNames: string[] = [];
  
      // Helper function to build a map of values to labels, including childComponents
      const buildOptionsMap = (opts: any[]): { [key: string]: string } => {
        const map: { [key: string]: string } = {};
  
        const traverse = (optionsList: any[]) => {
          optionsList.forEach((option: any) => {
            const valueKey = option.value || option.label;
            map[valueKey] = option.label;
  
            if (option.childComponents && option.childComponents.length > 0) {
              traverse(option.childComponents);
            }
          });
        };
  
        traverse(opts);
        return map;
      };
  
      if (Array.isArray(options)) {
        const optionsMap = buildOptionsMap(options);
        displayNames = selected.map((s: string) => optionsMap[s] || s);
      } else {
        // Handle the case when options is an object
        // Convert object to array and then build the options map
        const optionsArray = Object.entries(options).map(([key, value]) => {
          return { value: key, label: value };
        });
        const optionsMap = buildOptionsMap(optionsArray);
        displayNames = selected.map((s: string) => optionsMap[s] || s);
      }
  
      const namesString = displayNames.join(", ");
      filterText += ` ${filter.operator || "-"} ${namesString}`;
    } else {
      filterText = `${filter?.label || filterConfig?.label} (${selected.length} selected)`;
    }
  } else if (filterType === "number") {
    filterText += ` ${filter.operator.replace("_", " ")} ${filter.query || ""}`;
  } else if (filterType === "boolean" || filterType === "userIdIn") {
    // Handle boolean and userIdIn types
    const operation = filterConfig.operations.find((op: any) =>
      JSON.stringify(op.value) === JSON.stringify(filter.operator)
    );
    const label = operation ? operation.label : filter.operator;
    filterText += ` - ${label}`;
  } else {
    filterText = `${filter?.label || filterConfig?.label} (${selected.length} selected)`;
  }

  let removable = true;
  if (selectedView?.isGlobal) {
    selectedView.filters.forEach((selectedFilter: any) => {
      if (
        JSON.stringify({
          ...selectedFilter,
          name: selectedView.name,
        }).toLowerCase() === JSON.stringify(filter).toLowerCase()
      ) {
        removable = false;
      }
    });
  }

  return (
    <Badge
      onClick={handleBadgeClick}
      size="lg"
      radius="sm"
      variant="outline"
      styles={{
        root: {
          backgroundColor: "#EFEFFD",
          borderColor: "#EFEFFD",
          color: "black",
          fontSize: 12,
          fontWeight: 400,
          height: 32,
          cursor: "pointer",
        },
      }}
      sx={{ paddingRight: removable ? 5 : 9, fontSize: 10, ...sx }}
      rightSection={
        removable ? (
          <Tooltip
            sx={{ fontSize: 10 }}
            withArrow
            label="Remove filter"
            position="bottom"
          >
            <ActionIcon
              onClick={(event) => {
                event.stopPropagation(); // Prevents the click from bubbling to the Badge
                onRemoveClick(); // Call the specific remove function
              }}
              size="xs"
              color="blue"
              radius="xl"
              variant="transparent"
            >
              <IconX size={15} color="#5C5CEB99" />
            </ActionIcon>
          </Tooltip>
        ) : null
      }
    >
      <OverflownText
        maw={"200px"}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {filterText}
      </OverflownText>
    </Badge>
  );
};

export default FilterBadge;