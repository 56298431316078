import * as React from "react";
const QualtricsIcon = ({ width = 80, height = 43, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 79 43"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear0"
        gradientUnits="userSpaceOnUse"
        x1={206.399994}
        y1={7}
        x2={1475.199951}
        y2={925.599976}
        gradientTransform="matrix(0.0508039,0,0,0.0511296,0,0)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "rgb(12.941176%,85.882353%,66.666667%)",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.4}
          style={{
            stopColor: "rgb(0%,70.588235%,93.72549%)",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.4}
          style={{
            stopColor: "rgb(0.392157%,65.490196%,92.54902%)",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.6}
          style={{
            stopColor: "rgb(1.568627%,52.941176%,89.411765%)",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.7}
          style={{
            stopColor: "rgb(2.745098%,40.784314%,86.666667%)",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "rgb(37.254902%,10.196078%,89.803922%)",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
    <g id="surface1">
      <path
        style={{
          stroke: "none",
          fillRule: "evenodd",
          fill: "url(#linear0)",
        }}
        d="M 78.949219 0.015625 L 78.949219 42.988281 L 69.890625 42.988281 L 69.890625 13.6875 L 69.777344 13.6875 C 69.710938 14.105469 69.640625 14.527344 69.566406 14.945312 C 69.488281 15.359375 69.40625 15.777344 69.316406 16.191406 C 69.230469 16.609375 69.136719 17.023438 69.039062 17.441406 C 68.9375 17.851562 68.832031 18.261719 68.722656 18.671875 C 68.320312 20.148438 67.882812 21.679688 67.410156 23.265625 L 61.027344 42.972656 L 52.730469 42.972656 L 46.394531 23.265625 L 45.734375 21.023438 L 45.132812 18.871094 C 44.953125 18.113281 44.757812 17.292969 44.554688 16.40625 C 44.351562 15.519531 44.136719 14.449219 43.914062 13.207031 L 43.796875 13.207031 L 43.796875 42.988281 L 27.664062 42.988281 L 18.605469 27.96875 L 9.855469 42.988281 L 0.0117188 42.988281 L 13.652344 20.289062 L 1.21875 0.03125 L 11.910156 0.03125 L 19.535156 12.925781 L 26.738281 0.03125 L 47.769531 0.03125 L 57.277344 31.625 L 57.386719 31.625 L 66.253906 0.03125 Z M 35.390625 38.570312 L 35.390625 1.738281 L 24.257812 20.34375 Z M 35.390625 38.570312 "
      />
    </g>
  </svg>
);
export default QualtricsIcon;
