import { useContext } from 'react'
import IdeaContext from '../../IdeaContext';
import { Box, Text, Flex, Menu} from '@mantine/core';
import { IconCaretDown } from '@tabler/icons';
import PerspectiveList from './PerspectiveList';
import SyncContext from "../../../../context/SyncContext";

const BusinessSources = () => {
  const {
    type,
    setType
  } = useContext(IdeaContext);
  const { perspectiveConfigurations } = useContext(SyncContext);

  return (
    <Box px={40}>
      <Box mb={16} mt={26} py={2}>
        <Flex>
          <Text fz="14px" fw={600} mr='4px'>
            Perspective:
          </Text>
        
          <Menu position='bottom-start'>
            <Menu.Target>
              <Flex sx={{cursor: 'pointer'}}>
              
                <Text fz="14px" mr='4px'>
                  {perspectiveConfigurations?.find((i: any) => i.key == type)?.label}
                </Text>
                <IconCaretDown fill='black' size={14}/>
              </Flex>
           
            </Menu.Target>
            <Menu.Dropdown>
              {perspectiveConfigurations.map((perspective:any) => (
                <Menu.Item
                  onClick={()=>{
                    setType(perspective.key)
                  }}
                >
                  {perspective.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>  
        </Flex>   
      </Box>
      <PerspectiveList />
    </Box>
  );
};

export default BusinessSources