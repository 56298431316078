import { useContext } from "react";
import DiscoveryContext from "../DiscoveryContext";
import SimilarIdeaCard from "./SimilarIdeaCard";
import { Box, ScrollArea, Skeleton } from "@mantine/core";
import DiscoveryZeroState from "../DiscoveryZeroState";

const IdeasMatchesTab = () => {

  const { ideaMatches, loadingIdeasMatches } = useContext(DiscoveryContext);

  return (
    <>
      <ScrollArea
        h={`calc(100vh - 200px)`}
        mah={`calc(100vh - 200px)`} 
      >
        {loadingIdeasMatches ? (
          <Box w="99%" pr="20px" pl="8px" mt={8} pt={40}>
            {[1, 2, 3, 4].map((i) => (
              <Skeleton
                p={12}
                my={16}
                mx={36}
                key={i}
                m="sm"
                height={104}
                mb={0}
                sx={{ borderRadius: "8px" }}
              />
            ))}
          </Box>
        ) : ideaMatches && ideaMatches.length > 0 ? (
          <Box pt={40}>
            {ideaMatches.map((idea: any) => (
              <SimilarIdeaCard idea={idea?.idea} />
            ))}
          </Box>
        ) : (
          <DiscoveryZeroState
            text="There are no ideas that match your description. Please update the product idea to find similar ideas."
          />
        )}
      </ScrollArea>
    </>
  );
};

export default IdeasMatchesTab;
