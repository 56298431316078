import { Checkbox, Group, Flex, Text, Tooltip, Box } from "@mantine/core";
import { useContext, useEffect, useState } from "react";

import ProgressBarPart from "./ProgressBarPart";
import DiscoveryContext from "../../DiscoveryContext";


export const SingleSideCheckboxDiscovery = ({
  item,


}: {
  item: any;
  

}) => {



  console.log(item)

  const {
    showmatches,
   
  } = useContext(DiscoveryContext);




  return (
    <Checkbox
      styles={{
        label: {
          cursor: "pointer",
        },
        input: {
          cursor: "pointer",
        },
        labelWrapper: {
          width: "-webkit-fill-available",
          cursor: "pointer",
        },
      }}
      color="indigo"
      value={item?.value.toLowerCase()}
      w="100%"
      label={
        <Group position="apart" noWrap>
          <Tooltip label={item?.value}>
            <Text lineClamp={1} fz="12px" fw={500} color="#0A122E">
              {item?.value}
            </Text>
          </Tooltip>
          
          <Group noWrap>
            <Flex
              w="110px"
              h="8px"
              mr="8px"
              sx={{ borderRadius: "8px", overflow: "hidden" }}
            >
            
              {(showmatches) && <ProgressBarPart
                widthPercentage={ item?.matchesPercentage }
                color="#5C5CEB"
              />}

              <Box
                w={`${item?.matchesPercentage || "0"}%`}
                bg= {"#5C5CEB" }
              ></Box>
              <ProgressBarPart
                widthPercentage={ item?.bgPercentage }
                color="rgba(92, 92, 235, 0.6)"
              />
              <Box
                w={`${item?.bgPercentage}%`}
                bg="rgba(92, 92, 235, 0.6)"
              ></Box> 
            </Flex>

            <Flex h={16} w={62} align="center" justify="flex-end">
          
              {(showmatches) && (
                <>
                  <Text fz={39} color="#5C5CEB" mb={22} w={10}>
                    .
                  </Text>
                  <Text
                    color="#5C5CEB"
                    fz="13px"
                    w={15}
                    align="center"
                  >
                    { (item?.matchesCount) || 0 }
                  </Text>
                </>
              )} 
            </Flex>
          </Group>
        </Group>
      }
    />
  );
};