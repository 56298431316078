import { Box, Flex, Stack, Tooltip, Text, Menu } from "@mantine/core";
import {
  BagelAiIcon,
  FromBagelIcon,
  RequestIcon,
} from "../../../../icons/x-symbol-svgrepo-com";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { IconAlertOctagon, IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import { saveMatch, saveRequest, unLinkToProductItem } from "../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import IdeaContext from "../../IdeaContext";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { formatMonetaryValue, getNestedValue } from "../../Funcs";
import { formatBigMonetaryValueIdeas } from "../../../ideas/ideas-table-helpers/Helpers";

const CardAttributes = ({ item }: { item: any }) => {
  const auth0 = useAuth0();
  const { requestPriorities, allComponents, impactAggregationProperties } =
    useContext(SyncContext);

  const updatedImpactAggregationProperties = impactAggregationProperties.map(
    (prop: any) => ({
      ...prop,
      propertyName: prop.propertyName.replace("cxItem", "chat"),
      fieldPath: prop.fieldPath.replace("cxItem", "chat"),
      groupBy: prop.groupBy
        ? prop.groupBy.replace("cxItem", "chat")
        : prop.groupBy,
    })
  );

  const { loadEvidence } = useContext(IdeaContext);

  const [priority, setPriority] = useState<string>(
    item?.priority || "Unassigned"
  );
  /* console.log(priority) */
  const [color, setColor] = useState("");

  const source = item?.source;
  const origin = item?.origin || item?.textItem?.origin || item?.chat?.origin;
  const company = item?.company || null;
  const amount = item?.chat?.amount;
  const companyName = company?.name;
  const arr = company?.arr;
  const isMatch = item?.status === "match";
  /* let priority = item?.textItem?.priority || item?.priority || 'Unassigned'
  const isMatch = item?.status === 'match'
  const currentPriorityIndex = requestPriorities.findIndex((r:any)=> r.key.toLowerCase() === priority.toLowerCase())
  if (currentPriorityIndex=== -1){
    priority = 'Unassigned'
  }
  const color = requestPriorities[currentPriorityIndex]?.color */

  const onSelectSave = (type: string, value: string) => {
    let updatedValue = value;

    if (type === "componentId") {
      const found = allComponents.find((a: any) => a._id === value);
      updatedValue = found?.name;
    }

    /* if(isMatch && type === 'componentId'){
      const match = matches.find((m: any)=> m._id === item?._id)
      const index = matches.findIndex((m: any)=> m._id === item?._id)
      match.componentId = value
      const copy2 = [...matches]
      match.suggestedComponent = false
      const spliced = copy2.toSpliced(index,1,match)
      setMatches(spliced) 
    } */
    console.log({
      onSelectSave: {
        type,
        value,
      },
    });
    if (item) {
      const itemObj = {
        id: item?._id,
        [type]: value,
      };

      if (isMatch) {
        saveMatch(itemObj, auth0).then((res) => {
          console.log({ editOwner: res, itemObj });

          showNotification({
            title: "Match updated",
            color: "teal",
            message: `Match ${type} updated successfully`,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200,
          });
        });
      } else {
        console.log({ item, itemObj });
        saveRequest(itemObj, auth0)
          .then((response) => {
            console.log(response);
            loadEvidence();
            showNotification({
              title: `${
                type === "componentId"
                  ? "Product area"
                  : type === "priority"
                    ? "Priority"
                    : "Domain"
              } updated`,
              color: "teal",
              message: `${
                type === "componentId"
                  ? "Product area"
                  : type === "priority"
                    ? "Priority"
                    : "Domain"
              } updated to "${updatedValue}" successfully`,
              icon: <IconCheck size="1.1rem" />,
              autoClose: 1200,
            });
          })
          .catch((e: any) => console.log(e));
      }
    }
  };

  useEffect(() => {
    setPriority(item?.priority || "Unassigned");
    /* if (typeof priority !== 'string') {
      setPriority('Unassigned');
    } */
    /* setPriority(  item?.priority || item?.textItem?.priority || 'Unassigned') */
    const currentPriorityIndex = requestPriorities.findIndex(
      (r: any) => r.key.toLowerCase() === item?.priority?.toLowerCase()
    );
    if (currentPriorityIndex === -1) {
      setPriority("Unassigned");
    }
    setColor(requestPriorities[currentPriorityIndex]?.color);
  }, [item /* priority */]);

  return (
    <Flex h={32} justify="space-between" align="center">
      <Flex h={32} w={32} mr={4} align="center">
        <Stack mr={9} justify="center" align="center">
          {source === "suggestion" ? (
            <Tooltip label="Found by Bagel AI">
              <Stack h={20} w={20} align="center">
                <BagelAiIcon />
              </Stack>
            </Tooltip>
          ) : source === "bagel" || source === "search" ? (
            <Tooltip label="Added by PM">
              <Stack align="center" h={20} w={20}>
                <FromBagelIcon />
              </Stack>
            </Tooltip>
          ) : source === "request" ? (
            <Tooltip label="Request">
              <Stack align="center" h={20} w={20}>
                <RequestIcon />
              </Stack>
            </Tooltip>
          ) : (
            ""
          )}
        </Stack>
        {origin && (
          <Tooltip label={capitalizeFirstLetter(origin) || "N/A"}>
            <Stack
              h={20}
              w={25}
              mx={6}
              align="center"
              mb={origin === "hero" ? "3px" : "0px"}
            >
              <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
            </Stack>
          </Tooltip>
        )}

        <Tooltip label={companyName || "N/A"}>
          <Box h={32} w={140} px={9} sx={{ borderRight: "1px solid #D8D8DB" }}>
            <Text h={16} fz={12} fw={400}>
              Name
            </Text>
            <Text h={16} fz={12} fw={600} lineClamp={1} w={140}>
              {companyName || "N/A"}
            </Text>
          </Box>
        </Tooltip>

        <Flex>
          {updatedImpactAggregationProperties &&
            updatedImpactAggregationProperties.length > 0 &&
            updatedImpactAggregationProperties.map((impactItem: any) => (
              <Box
                h={32}
                w={120}
                px={9}
                sx={{ borderRight: "1px solid #D8D8DB" }}
              >
                <Tooltip label={impactItem?.label || "N/A"}>
                  <Text h={16} fz={12} fw={400} lineClamp={1}>
                    {impactItem?.label || "N/A"}
                  </Text>
                </Tooltip>

                <Text h={16} fz={12} fw={600} lineClamp={1}>
                  {impactItem.fieldPath &&
                  getNestedValue(item, impactItem.fieldPath)
                    ? formatBigMonetaryValueIdeas(
                      getNestedValue(item, impactItem.fieldPath),
                      (impactItem?.impact?.prefix || undefined)
                    )
                    : "N/A"}
                </Text>
              </Box>
            ))}
        </Flex>
      </Flex>
      <Box mr={45}>
        <Menu>
          <Menu.Target>
            <Tooltip label={`Priority: ${priority || "N/A"}`}>
              <Box sx={{ cursor: "pointer" }}>
                <Stack align="center" justify="center" ml={16} fz="14px">
                  {" "}
                  <IconAlertOctagon size={16} color={color} />{" "}
                </Stack>
              </Box>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            {requestPriorities.map((priorityItem: any) => (
              <Menu.Item
                w="180px"
                key={priorityItem.label}
                onClick={() => {
                  /* console.log(priorityItem) */
                  setPriority(priorityItem?.label);
                  setColor(priorityItem.color);
                  onSelectSave("priority", priorityItem.label);
                }}
              >
                {priorityItem.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Box>
    </Flex>
  );
};

export default CardAttributes;
