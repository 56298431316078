import { useAuth0 } from "@auth0/auth0-react";
import {
  ActionIcon,
  Box,
  Paper,
  Text,
  Title,
  Card,
  Button,
  Flex,
  Tooltip,
  Spoiler,
  Group,
  Anchor,
  Stack,
} from "@mantine/core";
import {
  IconCheck,
  IconSend,
  IconX,
} from "@tabler/icons";
import { useContext, useEffect, useRef, useState } from "react";
import {
  addCommentToRequest,
  deleteCommentFromRequest,
  getCommentsForRequest,
  updateRequestComment,
  validateRequest,
  searchMembers,
  searchUsers,
  assignRequestToNewIdea,
  getSuggestionsForIdea,
  getRequestMatches,
} from "../../../Api";
import { getBagelId, stripHtml } from "../../../utils";
import { getUsersFromTags } from "../../ideaNiv/helpers/tags";
import { Comments } from "./Comments";
import { ModalContentInterface } from "./ModalContent";
import { ReAssign } from "./ReAssign";
import { useNavigate } from "react-router-dom";
import { useHover } from "@mantine/hooks";
import { Loader } from "../../../icons/x-symbol-svgrepo-com";
import { showNotification } from "@mantine/notifications";
import { closeAllModals, openModal } from "@mantine/modals";
import LogoIcon from "../../layouts/LogoIcon";
import SyncContext from "../../../context/SyncContext";
import MentionTextarea, { MentionSuggestion } from "../../MentionTextarea";
import { useMentions } from "../../MentionTextarea/MentionContext";

interface User {
  id: string;
  email: string;
  name: string;
  picture: string;
}


export interface SideAccordionInterface extends ModalContentInterface {
  onUpdate: (useLoading?: boolean) => void;
  setOpened: ((bool: boolean) => void) | undefined;
  customizations: any;
  ownerInModal: any;
  componentIdInModal: any;
  productAreaNameInModal: any;
  updateOwner: (owner: any) => void;
  editOwner: (owner: any, notification?: boolean) => void;
  displayProductAreaMsg: boolean;
  setDisplayProductAreaMsg: any 
}

export const SideBar = ({
  idea,
  request,
  componentIdInModal,
  setValidatedRequests,
  onUpdate,
  updateOwner,
  editOwner,
  displayProductAreaMsg,
  setDisplayProductAreaMsg,

  ...rest
}: SideAccordionInterface) => {

  const auth0 = useAuth0();
  const { defaultPriority, defaultStatus, components, allComponents } =
    useContext(SyncContext);
  const { setSuggestions, setLoading: setSuggestionsLoading } = useMentions();
  const [sideBarType, setSideBarType] = useState(request.status ?? "");
  const [sideBarTypeCancel, setSideBarTypeCancel] = useState("");
  const [ideaMatches, setIdeaMatches] = useState<any[]>([]);
  const [commentValue, setCommentValue] = useState("");
  const [comments, setComments] = useState<any[]>([]);
  const [productAreaName, setProductAreaName] = useState<string | null>(rest.productAreaNameInModal || 'Unassigned')
  const [componentId, setComponentId] = useState(componentIdInModal)
  const [owner, setOwner] = useState<any | null>(rest.ownerInModal || null);
  const [ownerId, setOwnerId] = useState<string | null>(owner?._id || null);
  const [ownerName, setOwnerName] = useState<any>("Unassigned");
  const [noIdeaOwner, setNoIdeaOwner] = useState(false);
  const [loading, setLoading] = useState({
    getSuggestions: false,
    comment: false,
    validate: false,
    mentions: false,
    new: false,
  });
  const [matchId, setMatchId] = useState<any>(undefined);
  const [idaeMatch, setIdeaMatch] = useState<any>(undefined);
  const [allRequests, setAllrequests] = useState<any[]>([]);
  const viewport = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const getSuggestions = () => {
    
    const title = request?.textItem?.title || request?.title || ''
    const text = request?.textItem?.text || request?.description || ''
    getSuggestionsForIdea(title, text , auth0)
      .then((matches: any) => {

        const filtered = matches?.ideaMatches?.filter((m: any) => m.ideaId !== idea?._id) || [] ; 
        setIdeaMatches(filtered);
      })
      .catch((e) => console.log(e))
      .finally(() =>
        setLoading((prev) => ({
          ...prev,
          getSuggestions: false,
        }))
      );
  };

  const getIdeasMatches = ()=>{
    
    setLoading((prev) => ({
      ...prev,
      getSuggestions: true,
    })); 

    getRequestMatches(request._id, auth0).then((matches: any) => {

      const filtered = matches?.filter((m: any) => m.ideaId !== idea?._id) || [] ; 
      
      if(filtered.length === 0){
        getSuggestions()
      }
      else{
        setIdeaMatches(filtered);
        setLoading((prev) => ({
          ...prev,
          getSuggestions: false,
        }))
        
      } 
    })
      .catch((e) => console.log(e))
  }

  const scrollToBottom = () =>
    viewport?.current?.scrollTo({
      top: viewport?.current?.scrollHeight,
      behavior: "smooth",
    });

  /* const owner = rest?.ownerInModal ?? null */
  /*  const owner =undefined */
  const title = request.title ?? "";
  const description = request.text ?? "";

  const newIdeaObj = {
    title: title,
    description: description,
    componentId: componentId, 
    ownerId: null,
    rolloutDate: null,
    status: defaultStatus,
    priority: defaultPriority,
  };

  const createNew = () => {
    if (!componentId) {
      setDisplayProductAreaMsg(true);
      return
    }

    if (componentId && !loading.new) {

      const foundComponent = allComponents.find((c: any)=> c._id === componentIdInModal)

      if(!foundComponent){
        setDisplayProductAreaMsg(true);
        return
      }

      if(foundComponent){
        newIdeaObj.ownerId = foundComponent.ownerId
        request.ownerId = foundComponent.ownerId
      }

      setLoading((prev) => ({
        ...prev,
        new: true
      }));
    
      assignRequestToNewIdea(
        { idea: newIdeaObj, request },
        auth0
      )
        .then((response) => {
          const ideaId = response.createNewIdeaRes?.idea?._id;
          showNotification({
            title: "Assigned successfully!",
            color: "teal",
            message: <Box>
              <Text>New idea has been created</Text>
              <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
              </Anchor>
            </Box>,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
          if (ideaId) {
            setTimeout(() => {
              window.open(`/idea/${ideaId}`);
            }, 1300);
          } else {
            console.log(`did not receive request id for new idea`, response);
          }
        })
        .catch(console.log)
        .finally(() => {
          onUpdate(true);
          setLoading((prev) => ({
            ...prev,
            new: false
          }));
          navigate("/inbox");
          setTimeout(() => {
            closeAllModals();
          }, 1300);
        });
    }
  };

  const handleSubmitComment = () => {
    setLoading((prev) => ({
      ...prev,
      comment: true,
    }));
    const _commentValue = commentValue.trim();
    const usersMentioned = getUsersFromTags(_commentValue);
    console.log({ usersMentioned });
    return addCommentToRequest(
      idea?._id,
      request._id,
      commentValue,
      usersMentioned,
      auth0
    )
      .then((response) => {
        if (response.data.success) {
          comments.push(response.data.comment);
          setCommentValue("");
        }
      })
      .catch((e: any) => console.log(e))
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          comment: false,
        }));
      });
  };

  const deleteComment = (commentId: string) => {
    const commentsFiltered = comments.filter((c: any) => c._id !== commentId);
    setComments(commentsFiltered);
    deleteCommentFromRequest(request._id, commentId, auth0)
      .then(() => {
        onUpdate();
      })
      .catch((e: any) => console.log(e));
  };

  const editComment = (comment: any, text: string) => {
    comment.text = text;
    const editedCommentIndex = comments.findIndex(
      (c: any) => comment._id === c._id
    );
    comments.splice(editedCommentIndex, 1, comment);

    const commentValue = text.trim();
    const usersMentioned = getUsersFromTags(commentValue);
    updateRequestComment(comment._id, commentValue, usersMentioned, auth0)
      .then((response) => {
        console.log(response);
        /* onUpdate() */
      })
      .catch((e: any) => console.log(e));
  };

  const assignRequestToIdea = (idea: {
    _id: string | undefined;
    title: any;
  }) => {
    setLoading({ ...loading, validate: true });

    validateRequest(auth0, request._id, idea._id)
      .then(() => {
        showNotification({
          title: "Assignment Successful",
          message: `The request has been assigned to "${idea.title}" successfully.`,
          color: "green",
          autoClose: 1200
        });
        onUpdate();

        closeAllModals();
      })
      .catch((error) => {
        console.error(error);
        showNotification({
          title: "Assignment Failed",
          message:
            "There was a problem assigning the request. Please try again.",
          color: "red",
          autoClose: 1200
        });
      })
      .finally(() => setLoading({ ...loading, validate: false }));
  };

  const reAssign = () => {
    setSideBarType("new");
    setSideBarTypeCancel("cancel");
  };

  const openAssignedModal = (idea: any) => {
    openModal({
      modalId: "assignConfirmation",
      title: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <LogoIcon /> {/* Ensure LogoIcon is correctly imported */}
            <span>Assign Request</span>
          </div>
        </div>
      ),
      centered: true,
      size: "lg",
      overlayColor: "rgba(0, 0, 0, 0.55)",
      overlayOpacity: 0.85,
      overlayBlur: 3,
      children: (
        <Stack spacing="md" align="center">
          <Text size="sm" align="center" style={{ marginBottom: 15 }}>
          The request will be assigned to this idea.
          </Text>
          <Anchor href={`/idea/${idea._id}`} target="_blank" style={{ marginTop: 10, marginBottom: 20 }}>
            <Text color="indigo" size="md" weight={600}>
              {idea.title}
            </Text>
          </Anchor>
          <Flex justify="space-around" style={{ width: '100%' }}>
            <Button
              mx="md" 
              color="indigo"
              onClick={() => {
                assignRequestToIdea(idea); 
                closeAllModals();
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Stack>
      ),
      styles: (theme) => ({
        modal: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#FFFFFF',
          borderColor: theme.colors.gray[3],
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          borderRadius: '16px', 
        },
        header: {
          borderBottom: `1px solid ${theme.colors.gray[3]}`,
          paddingBottom: theme.spacing.sm,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        body: {
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.sm,
        },
      }),
    });
  };

  const openConfirmNewIdeaModal = () => {
    openModal({
      title: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <LogoIcon /> 
            <span>Confirm New Idea Assignment</span>
          </div>
        </div>
      ),
      centered: true,
      size: "lg",
      overlayColor: "rgba(0, 0, 0, 0.55)",
      overlayOpacity: 0.85,
      overlayBlur: 3,
      children: (
        <Stack spacing="md" align="center">
          <Text size="sm" align="center" style={{ marginBottom: 15 }}>
          A new Idea will be created for this request.
          </Text>
          <Flex justify="space-around" style={{ width: '100%', marginTop: 10, marginBottom: 20 }}>
            <Button
              mx="md" 
              color="indigo"
              
              onClick={() => {
                createNew();
                closeAllModals();
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Stack>
      ),
      styles: (theme) => ({
        modal: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#FFFFFF',
          borderColor: theme.colors.gray[3],
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          borderRadius: '16px', 
        },
        header: {
          borderBottom: `1px solid ${theme.colors.gray[3]}`,
          paddingBottom: theme.spacing.sm,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        body: {
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.sm,
        },
      }),
    });
  };

  

  const getComments = () => {
    getCommentsForRequest(request._id, auth0)
      .then((res) => {
        setComments(res.data.comments ?? []);
      })
      .catch((e) => console.log(e));
  };

  const setOwnerPick = (matchId: string | undefined, idea: any) => {
    setMatchId(matchId);
    setIdeaMatch(idea);
    setNoIdeaOwner(true);
  };

  const searchUser = ({
    query,
  }: {
    query: string;
  }): Promise<MentionSuggestion[]> => {
    setSuggestionsLoading(true);

    return new Promise<MentionSuggestion[]>((resolve, reject) => {
      console.log({ query });

      Promise.all([searchUsers(query, auth0), searchMembers(query, auth0)])
        .then(([users, members]) => {
          console.log({ users, members });

          const userSuggestions: MentionSuggestion[] =
            users?.map((user: any) => ({
              id: user._id,
              email: user.email,
              name: user.name,
              picture: user.picture,
            })) ?? [];

          const memberSuggestions: MentionSuggestion[] =
            members
              ?.filter((member: any) => member.name)
              .map((member: any) => ({
                id: member._id,
                email: member.email,
                name: member.name,
                picture: member.picture,
              })) ?? [];

          const combinedSuggestions = [
            ...userSuggestions,
            ...memberSuggestions,
          ];
          const emailToUserMap = new Map<string, MentionSuggestion>();

          combinedSuggestions.forEach((suggestion) => {
            if (
              !emailToUserMap.has(suggestion.email) ||
              memberSuggestions.find(
                (member) => member.email === suggestion.email
              )
            ) {
              emailToUserMap.set(suggestion.email, suggestion);
            }
          });

          const uniqueSuggestions = Array.from(emailToUserMap.values());
          console.log({ emailToUserMap });

          setSuggestions(uniqueSuggestions); 
          resolve(uniqueSuggestions);
        })
        .catch((error) => {
          console.error("Error searching users and members:", error);
          reject(error);
        })
        .finally(() => setSuggestionsLoading(false)); 
    });
  };

  useEffect(() => {
    getComments();
    scrollToBottom();
  }, []);

  useEffect(() => {
    getIdeasMatches()
  }, [displayProductAreaMsg]);

  useEffect(() => {
    setComponentId(componentIdInModal)
  }, [componentIdInModal]);



  return (
    <Stack
      justify="space-between"
      h="calc(100vh - 182px)"
      pb={0}
      sx={{
        borderLeft: "1px solid #E5E7EB",
      }}
      spacing={0}
    >
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          
        }}
      >
        <Paper
          radius="md"
          sx={{
            background: "#F5F6FE",
          }}
          p="sm"
        >
          <Flex justify="space-between">
            <Title order={5} mb="xs">
              {sideBarType == "validated" ? "Assigned" : "Assign"} idea
            </Title>
            {sideBarTypeCancel === "cancel" && (
              <Tooltip label="Cancel re assign">
                <ActionIcon
                  onClick={() => {
                    setSideBarType("validated");
                    setSideBarTypeCancel("");
                  }}
                >
                  <IconX size={20} />
                </ActionIcon>
              </Tooltip>
            )}
          </Flex>

          {sideBarType == "validated" && (
            <IdeaItem
              request={request}
              onUpdate={onUpdate}
              item={idea}
              matchId={undefined}
              approveButton={false}
              bagel={false}
              reassignButton={true}
              reAssign={reAssign}
              openAssignedModal={openAssignedModal}
              setOpened={rest.setOpened}
              setOwnerPick={setOwnerPick}
            />
          )}
          {sideBarType != "validated" && (
            <ReAssign
              idea={idea}
              request={request}
              getRequestFn={onUpdate}
              TextInputW="100%"
              BoxW="38vw"
              setOpened={rest.setOpened}
              openAssignedModal={openAssignedModal}
            />
          )}
          <Box mt="md" />

          {idea?.title  &&
            sideBarType == "new" &&
            request._id &&
            sideBarTypeCancel !== "cancel" && (
            <>
              <Text size="xs" mb="xs" fw={500}>
                  Suggested by GTM
              </Text>{" "}
              <IdeaItem
                request={request}
                onUpdate={onUpdate}
                item={idea}
                matchId={undefined}
                isValidated
                approveButton={true}
                bagel={false}
                reassignButton={false}
                reAssign={console.log}
                openAssignedModal={openAssignedModal}
                setOpened={rest.setOpened}
                setOwnerPick={setOwnerPick}
              />
            </>
          )}
          {sideBarType == "new" && (
            <>
              {" "}
              <Text size="xs" mb="xs" fw={500}>
                Suggested by Bagel
              </Text>
              {ideaMatches.length > 0 ? (
                ideaMatches
                  .slice(0, 2)
                  ?.map((ideaMatch) => (
                    <IdeaItem
                      request={request}
                      onUpdate={onUpdate}
                      item={ideaMatch.idea}
                      approveButton={false}
                      matchId={ideaMatch._id}
                      bagel={true}
                      reassignButton={false}
                      reAssign={console.log}
                      openAssignedModal={openAssignedModal}
                      setOpened={rest.setOpened}
                      setOwnerPick={setOwnerPick}
                    />
                  ))
              ) : loading.getSuggestions 
                ? 
                <Box mb={28}>
                  <Loader/>
                </Box>
                
                :
              
                (
                  <Card
                    withBorder
                    radius="md"
                    sx={{ borderColor: "#EAEAFE" }}
                    mb="sm"
                  >
                    <Text>No suggestions found</Text>
                  </Card>
                )}
            </>
          )}
          {displayProductAreaMsg && (
            <Stack align="center">
              <Text m="sm">
                {" "}
                Please select product area before assigning new idea
              </Text>
            </Stack>
          )}

          {!displayProductAreaMsg && sideBarType != "validated" && (
            <Button
              fullWidth
              radius="md"
              onClick={createNew}
              loading={loading.new}
              styles={(theme) => ({
                root: {
                  backgroundColor: "#EAEAFE",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#EAEAFE", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              sx={{
                border: "1px solid #5C5CEB",
                color: "#5C5CEB",
              }}
            >
              + Assign new idea
            </Button>
          )}
        </Paper>
        <Title mt="xl" mx="xs" order={5} mb="xs">
          Activities
        </Title>
        {/* <Divider label="Activities" variant="solid" labelPosition="center" /> */}
        <Comments
          idea={idea}
          request={request}
          origin={origin}
          comments={comments}
          deleteComment={deleteComment}
          editComment={editComment}
          handleSubmitComment={handleSubmitComment}
          loading={loading}
        />
      </Box>

      {/* <Textarea
        value={commentValue}
        onChange={(event) => setCommentValue(event.currentTarget.value)}
        py="md"
        px="26px"
        radius="md"
        sx={{
          background: "#F5F6FE",
          borderTop: "1px solid #E5E7EB",
        }}
        placeholder="Your comment"
        autosize
        minRows={1}
        maxRows={3}
        rightSection={
          <ActionIcon
            onClick={handleSubmitComment}
            size="md"
            mr={40}
            variant="filled"
            color="blue"
            radius="md"
            loading={loading.comment}
            disabled={commentValue == ""}
          >
            <IconSend size={20} />
          </ActionIcon>
        }
      /> */}
      <Box
        /* px='30' */
        sx={{
          background: "#F5F6FE",
          borderTop: "1px solid #E5E7EB",
          padding: 10,
        }}
      >
        <MentionTextarea<MentionSuggestion>
          placeholder="Your comment"
          getMentions={searchUser}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          editable
          clearOnRightSectionClick
          debounceWait={1000}
          
          sx={{
            background: "#fff",
            border: "1px solid #D8D8DB",
            borderRadius: 8,
            fontSize: 12,
          }}
          rightSection={
            <ActionIcon
              onClick={handleSubmitComment}
              mr={4}
              variant="filled"
              color="blue"
              radius="md"
              loading={loading.comment}
              disabled={commentValue == ""}
            >
              <IconSend size={20} />
            </ActionIcon>
          }
        />
      </Box>
    </Stack>
  );
};

const IdeaItem = ({
  request,
  item,
  matchId,
  approveButton,
  onUpdate,
  bagel,
  reassignButton,
  reAssign,
  openAssignedModal,
  setOpened,
  setOwnerPick,
}: {
  request: any;
  item: any;
  matchId: string | undefined;
  isValidated?: boolean;
  approveButton: boolean;
  onUpdate: (useLoading?: boolean) => void;
  bagel: boolean;
  reassignButton: boolean;
  reAssign: () => void;
  openAssignedModal: (idea: any) => void;
  setOpened: ((bool: boolean) => void) | undefined;
  setOwnerPick: (matchId: string | undefined, ideaId: string) => void;
}) => {
  const { hovered, ref } = useHover();
  const auth0 = useAuth0();
  const userId = getBagelId(auth0.user);
  const [loading, setLoading] = useState({
    validate: false,
  });

  const onValidate = () => {
    openAssignedModal(item);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Card
        withBorder
        radius="md"
        sx={{
          borderColor: "#EAEAFE",
          '&:hover': {
            boxShadow: '0 4px 30px rgba(0,0,0,0.1)',
            transform: 'scale(1.01)',
          }
        }}
        mb="sm"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        w='39.5vw'
      >
        <Group position="apart" noWrap>
          <Box>
            <Anchor
              sx={{ color: "#5C5CEA", whiteSpace: "nowrap" }}
              size="xs"
              href={`/idea/${item._id}`}
              target="_blank"
            >
              <Text
                fw={500}
                fz="15px"
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "28vw",
                }}
              >
                {item?.title}
              </Text>
            </Anchor>
  
            <Spoiler
              maxHeight={23}
              showLabel="see more"
              hideLabel="Hide"
              sx={(theme) => ({
                fontSize: theme.fontSizes.sm,
                button: {
                  color: "#5B60E3",
                  fontSize: theme.fontSizes.sm,
                },
              })}
              mb="xs"
            >
              <Text
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "28vw",
                }}
              >
                {stripHtml(item?.description)}
              </Text>
            </Spoiler>
          </Box>
        </Group>
  
        {/* Idea Attributes */}
        <Flex justify="space-between" mt="md">
          <Box>
            <Text size="xs" color="dimmed">Owner: {item?.owner?.name || '-'}</Text>
          </Box>
          <Box>
            <Text size="xs" color="dimmed">Status: {item?.status || '-'}</Text>
          </Box>
          <Box>
            <Text size="xs" color="dimmed">Priority: {item?.priority || '-'}</Text>
          </Box>
          <Box>
            <Text size="xs" color="dimmed">Product Area: {item?.componentObj?.name || '-'}</Text>
          </Box>
          <Box>
            <Text size="xs" color="dimmed">Companies: {item?.aggregations?.accounts?.count || '-'}</Text>
          </Box>
        </Flex>
  
        
        {isHovered && (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            position: 'absolute', 
            right: '10px', 
            bottom: '18px', 
            width: 'auto', 
          }}>
            {reassignButton ? (
              <Button mx="md" color="indigo" onClick={(e) => {e.stopPropagation(); reAssign();}}>
                Re assign
              </Button>
            ) : (
              <Button
                mx="md" 
                color="indigo"
                onClick={(e) => {e.stopPropagation(); onValidate();}}
              >
                Assign
              </Button>
            )}
          </Box>
        )}
      </Card>
    </>
  );
};
