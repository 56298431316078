import { useContext, useState } from "react";
import SyncContext from "../../context/SyncContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useHover } from "@mantine/hooks";
import { getBagelId, stripHtml } from "../../utils";
import { saveRequestStarred } from "../../Api";
import { Box, Flex, Stack, Tooltip, Text, ActionIcon } from "@mantine/core";
import { FromBagelIcon, NewRequestIcon, PurpuleBagelAiIcon, RequestIcon } from "../../icons/x-symbol-svgrepo-com";
import { IconAlertOctagon, IconSearch, IconStar } from "@tabler/icons";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { SourceIcon } from "../../utils/SourceIcon";
import { OverflownText } from "../OverflownText";
import moment from "moment";
import { formatMonetaryValue } from "../ideaNiv/Funcs";
import DiscoveryContext from "./DiscoveryContext";


const EvidenceCardDiscovery = ({
  item,
  index,
  fromSearch,
}: {
  item: any;
  index: number;
  fromSearch: boolean;

}) => {

  const { chosenEvidence, chooseMatch } = useContext(DiscoveryContext);
  
  const { requestPriorities} = useContext(SyncContext)


  const auth0 = useAuth0()
  const { hovered, ref } = useHover();
  const [priorityCapital, setPriorityCapital] = useState("");
  const [starred, setStarred] = useState(item?.starred?.includes(getBagelId(auth0.user)))
  const isMatch = item?.status !== 'validated'
  const company = item?.company;
  const customer = company?.name || "N/A";
  const description = item?.textItem?.title || item?.title || item?.textItem?.text || item?.text || null
  let priority =  item?.textItem?.priority || item?.priority || 'Unassigned'
  /* if (typeof priority !== 'string') {
    throw new Error("priority is not a string");
  } */
  if (typeof priority !== 'string') {
    /* console.warn("priority is not a string. Converting to string."); */
    priority = 'Unassigned';
  }
  const currentPriorityIndex = requestPriorities.findIndex((r:any)=> r?.label.toLowerCase() === priority.toLowerCase())
  /* if (currentPriorityIndex=== -1){
    priority = 'Unassigned'
  } */
  const color = requestPriorities[currentPriorityIndex]?.color
  const isOppurtunity = item?.chat?.itemType === 'opportunity'
  const isChosen = chosenEvidence && chosenEvidence?._id === item?._id

  const submitter =
    (item?.internalSubmitter || item?.cxSubmitter) ??
    item?.submitter;
  const submitterName = submitter ? submitter?.name || submitter?.displayName : item?.submitterEmail;
  const updatedAt = item?.updatedAt ? new Date(item?.updatedAt) : item?.textItem?.updatedAt ? new Date(item?.textItem?.updatedAt) : null

  const toggleFavourites = () =>{
    
    const request = {
      id: item._id,
      push: !starred,
    };
    saveRequestStarred(request, auth0)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));

    setStarred(!starred)
  };
  


  return (
    <Flex
      ref={ref}
      h="66px"
      fz="16px"
      my="8px"
      bg={isChosen? 'rgba(92, 92, 235, 0.1)' : isMatch ? "#F0F0FA" : "#FFF"}
      align="center"
      onClick={() => 
        chooseMatch(item._id, fromSearch)

        

      }
      sx={{
        border: (hovered || isChosen)
          ? "1px solid #5C5CEB"
          : isMatch
            ? "1px dashed #5C5CEB"
            : "1px solid #DFDEFD",
        cursor: "pointer",
        borderRadius: 12,
        overflow:'hidden'
      }}
      justify="space-between"
    >
      <Flex h="100%">
        
        <Flex sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}>
          {!fromSearch ? 
            <Stack
              w="52px"
              h="100%"
              bg={(item?.source === "request" && item?.status === 'new') ? 'rgba(92, 92, 235, 0.8)' : "#fff"}
              align="center"
              justify="center"
              sx={{ borderTopLeftRadius: "11.3px", borderBottomLeftRadius: "11.3px" }}
            >
              {(item.source === "suggestion" || item.status === "match") ? (
                <Tooltip label="Found by Bagel AI">
                  <Box>
                    <PurpuleBagelAiIcon />
                  </Box>
                </Tooltip>
              ) : (item.source === "bagel" || item.source === 'search') ? (
                <Tooltip label="Added by PM">
                  <Box>
                    <FromBagelIcon />
                  </Box>
                </Tooltip>
              ) : (item.source === "request" && item.status === "validated") ? (
                <Tooltip label={`Request connected by ${submitterName || 'N/A'}`}>
                  <Box>
                    <RequestIcon />
                  </Box>
                </Tooltip>
              ) :
                (item.source === "request" && item.status === "new") ? (
                  <Tooltip label={`Request connected by ${submitterName || 'N/A'}`}>
                    <Box>
                      <NewRequestIcon />
                    </Box>
                  </Tooltip>
                ) :
                  (
                    ""
                  )}
            </Stack>
            : 
            <Stack
              w="52px"
              h="100%"
              bg="#fff"
              align="center"
              justify="center"
              sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}
              
            >
              <IconSearch size={18} color="#5C5CEB"/>
            </Stack>} 
       
  
          <Stack w="52px" bg="#F8F9FA" align="center" justify="center">
            <Tooltip label={capitalizeFirstLetter(item?.origin || item?.chat?.origin || "N/A")}>
              <Box>
                {(item?.origin || item?.chat?.origin) &&
                  <SourceIcon
                    width={22}
                    height={22}
                    sourceName={
                      item.origin?.toLowerCase() ||
                    item.chat?.origin?.toLowerCase() || 'n/a'
                    }
                  /> 
                }
              </Box>
            </Tooltip>
          </Stack>
        </Flex>
        

        <Box p="12px" w="100%">
          <Flex >
            <Text
              bg="#5C5CEB1A"
              color="#5C5CEB"
              fz="13px"
              fw={600}
              lineClamp={1}
              sx={{ lineHeight: "20px", borderRadius: '4px' }}
              px={4}
              py={2}
            >
              {item?.businessName || 'General'}
            </Text>
            
          </Flex>
          <OverflownText
            fw={600}
            lineClamp={1}
            fz="13px"
            sx={{ 
              lineHeight: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {stripHtml(description)}
          </OverflownText>
        </Box>
      </Flex>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Box pl={8} mr={12} w="104px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text fz="12px" color="#585C68">
              Name
            </Text>
            
            <OverflownText fw={600} fz="12px" lineClamp={1} color="#212529">
              {company?.name || "N/A"}
            </OverflownText>            
          </Box>
          <Box pl={8} mr={12} w="82px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text color="#585C68" fz="12px">
              Updated
            </Text>
            <Text fw={600} fz="12px" lineClamp={1} color="#212529">
              {updatedAt ? moment(updatedAt).format("MM-DD-YY") : ""}
            </Text>
          </Box>

          {isOppurtunity ?  <Box pl={8} mr={12} w="74px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text color="#585C68" fz="12px">
              Opportunity
            </Text>
            <Text fw={600} fz="12px" lineClamp={1} color="#212529">
              {/* {item?.chat?.amount ? "$ " + item?.chat?.amount?.toLocaleString() : 'N/A'} */}
              {item?.chat?.amount ? formatMonetaryValue(item?.chat?.amount) : 'N/A'}
              
            </Text>
          </Box>:
            <Box pl={8} mr={12} w="74px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
              <Text color="#585C68" fz="12px">
                ARR
              </Text>
              <Text fw={600} fz="12px" lineClamp={1} color="#212529">
             
                {/* {company?.arr ?"$ " + company?.arr?.toLocaleString() : 'N/A' } */}
                {company?.arr ? formatMonetaryValue(company?.arr) : 'N/A'}
            
              </Text>
            </Box>
          }
          <Tooltip label={`Priority: ${ priority || "N/A"}`}>
            <Box>
              <Stack align="center" justify="center" ml={16} fz="14px" >
                {" "}
                <IconAlertOctagon size={16}  color={color} />{" "}
              </Stack>
            </Box>
          </Tooltip>
        </Flex>

        {!isMatch ? (
          <Flex w="20px" mr={18} ml={16}>
            <ActionIcon
              onClick={() => {
                toggleFavourites();
              }}
            >
              <IconStar
                color={auth0.user && starred ? "#FDAE1680" : "#D1D5DB"}
                fill={auth0.user && starred ? "#FDAE1680" : "#fff"}
                size={16}
                strokeWidth={2}
              />
            </ActionIcon>
          </Flex>
        ) : (
          <Flex mr={18} ml={16} w="20px"></Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EvidenceCardDiscovery;