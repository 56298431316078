import { Box } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { OverflownText } from "../../../OverflownText";

export const TextCell: React.FC<any> = ({ data, col, renderParams }) => {
  const value = getNestedFieldValue(data, col.accessor) || renderParams.default
  return (
    <Box
      sx={{
        maxWidth: renderParams.maxWidth || "350px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        /* backgroundColor: 'black' */
      }}
    >
      <OverflownText
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        overTooltip={renderParams.tooltip}
        {...renderParams}
      >
        {value}
      </OverflownText>
    </Box>
  );
};