import {  ActionIcon, Box, Flex, Stack, Text, Tooltip } from "@mantine/core"
import { SourceIcon } from "../../../utils/SourceIcon";
import { IconAlertOctagon, IconSearch, IconStar } from "@tabler/icons"
import moment from "moment";
import { useContext, useState } from "react";
import IdeaContext from "../IdeaContext";
import { getBagelId, stripHtml } from "../../../utils";
import SyncContext from "../../../context/SyncContext";
import { useAuth0 } from "@auth0/auth0-react";
import { saveRequestStarred } from "../../../Api";
import { useHover } from "@mantine/hooks";
import { BusinessNameNotification, FromBagelIcon, NewRequestIcon, PurpuleBagelAiIcon, RequestIcon, ConfidenceLevelIcon } from "../../../icons/x-symbol-svgrepo-com";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { OverflownText } from "../../OverflownText";

import posthog from "posthog-js";
import { formatBigMonetaryValueIdeas } from "../../ideas/ideas-table-helpers/Helpers";
import { getNestedValue } from "../Funcs";

const EvidenceCardNiv = ({
  item,
  chooseMatch,
  index,
  fromSearch,
  chosenEvidence,
}: {
  item: any;
  chooseMatch: any;
  index: number;
  fromSearch: boolean;
  chosenEvidence: any
}) => {

  const { requestPriorities, displayState, impactAggregationProperties } = useContext(SyncContext)

  console.log(displayState)

  const {
    getUnreadNotificationsCountByRequest
  } = useContext(IdeaContext);

  const updatedImpactAggregationProperties = impactAggregationProperties.map((prop:any) => ({
    ...prop,
    propertyName: prop.propertyName.replace('cxItem', 'chat'),
    fieldPath: prop.fieldPath.replace('cxItem', 'chat'),
    groupBy: prop.groupBy ? prop.groupBy.replace('cxItem', 'chat') : prop.groupBy,
  }));

  const firstImpactValue = updatedImpactAggregationProperties[0]?.fieldPath
    ? getNestedValue(item, updatedImpactAggregationProperties[0]?.fieldPath)
    : null;
  const firstPrefix = updatedImpactAggregationProperties[0]?.impact?.prefix || undefined;
  const auth0 = useAuth0();
  const { hovered, ref } = useHover();
  const [starred, setStarred] = useState(
    item?.starred?.includes(getBagelId(auth0.user))
  );
  const isMatch = item?.status === "match";
  const company = item?.company;
  const description =
    item?.textItem?.title ||
    item?.title ||
    item?.textItem?.text ||
    item?.text ||
    null;
  let priority = item?.textItem?.priority || item?.priority || "Unassigned";

  if (typeof priority !== 'string') {

    priority = 'Unassigned';
  }
  const currentPriorityIndex = requestPriorities.findIndex((r:any)=> r?.label.toLowerCase() === priority.toLowerCase())

  const color = requestPriorities[currentPriorityIndex]?.color
  const isOppurtunity = item?.chat?.itemType === 'opportunity'
  const isChosen = chosenEvidence && chosenEvidence?._id === item?._id

  const submitter =
    (item?.internalSubmitter || item?.cxSubmitter) ??
    item?.submitter;
  const submitterName = submitter ? submitter?.name || submitter?.displayName : item?.submitterEmail;
  const createdAt = item?.createdAt ? new Date(item?.createdAt) : undefined

  const confidenceLevel = item?.level


  const toggleFavourites = () =>{
    
    const request = {
      id: item._id,
      push: !starred,
    };
    saveRequestStarred(request, auth0)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));

    setStarred(!starred)
  };
  
  return (
    <Flex
      ref={ref}
      h="66px"
      fz="16px"
      my="8px"
      bg={isChosen? 'rgba(92, 92, 235, 0.1)' : isMatch ? "#F0F0FA" : "#FFF"}
      align="center"
      onClick={() => {
        chooseMatch(item._id, fromSearch);
        posthog.capture('suggested_clicked', item)
      }}
      sx={{
        border: (hovered || isChosen)
          ? "1px solid #5C5CEB"
          : isMatch
            ? "1px dashed #5C5CEB"
            : "1px solid #DFDEFD",
        cursor: "pointer",
        borderRadius: 12,
        overflow:'hidden'
      }}
      justify="space-between"
    >
      <Flex h="100%">
        
        <Flex sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}>
          {!fromSearch ? 
            <Stack
              w="52px"
              h="100%"
              bg={(item?.source === "request" && item?.status === 'new') ? 'rgba(92, 92, 235, 0.8)' : "#fff"}
              align="center"
              justify="center"
              sx={{ borderTopLeftRadius: "11.3px", borderBottomLeftRadius: "11.3px" }}
            >
              {(item.source === "suggestion" || item.status === "match") ? (
                <Tooltip label="Found by Bagel AI">
                  <Box>
                    <PurpuleBagelAiIcon />
                  </Box>
                </Tooltip>
              ) : (item.source === "bagel" || item.source === 'search') ? (
                <Tooltip label="Added by PM">
                  <Box>
                    <FromBagelIcon />
                  </Box>
                </Tooltip>
              ) : (item.source === "request" && item.status === "validated") ? (
                <Tooltip label={`Request connected by ${submitterName || 'N/A'}`}>
                  <Box>
                    <RequestIcon />
                  </Box>
                </Tooltip>
              ) :
                (item.source === "request" && item.status === "new") ? (
                  <Tooltip label={`Request connected by ${submitterName || 'N/A'}`}>
                    <Box>
                      <NewRequestIcon />
                    </Box>
                  </Tooltip>
                ) :
                  (
                    ""
                  )}
            </Stack>
            : 
            <Stack
              w="52px"
              h="100%"
              bg="#fff"
              align="center"
              justify="center"
              sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}
              
            >
              <IconSearch size={18} color="#5C5CEB"/>
            </Stack>} 
       
  
          <Stack w="52px" bg="#F8F9FA" align="center" justify="center">
            <Tooltip label={capitalizeFirstLetter(item?.origin || item?.chat?.origin || "N/A")}>
              <Box>
                {(item?.origin || item?.chat?.origin) &&
                  <SourceIcon
                    width={22}
                    height={22}
                    sourceName={
                      item.origin?.toLowerCase() ||
                    item.chat?.origin?.toLowerCase() || 'n/a'
                    }
                  /> 
                }
              </Box>
            </Tooltip>
          </Stack>

        </Flex>
        

        <Box p="12px" w="100%">
          <Flex >
            <Text
              bg="#5C5CEB1A"
              color="#5C5CEB"
              fz="13px"
              fw={600}
              lineClamp={1}
              sx={{ lineHeight: "20px", borderRadius: '4px' }}
              px={4}
              py={2}
            >
              {item?.businessName || 'General'}
            </Text>
            {getUnreadNotificationsCountByRequest(item?._id) > 0
            && <Box  h={16} w={16} py={2}>
              <BusinessNameNotification/>
            </Box>}
          </Flex>
          <OverflownText
            fw={600}
            lineClamp={1}
            fz="13px"
            sx={{ 
              lineHeight: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {stripHtml(description)}
          </OverflownText>
        </Box>
      </Flex>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Box pl={8} mr={12} w="114px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text fz="12px" color="#585C68">
              Name
            </Text>
            
            <OverflownText fw={600} fz="12px" lineClamp={1} color="#212529">
              {company?.name || "N/A"}
            </OverflownText>            
          </Box>
          <Box pl={8} mr={12} w="82px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text color="#585C68" fz="12px">
              Created
            </Text>
            <Text fw={600} fz="12px" lineClamp={1} color="#212529">
              {createdAt ? moment(createdAt).format("MM-DD-YY") : ""}
            </Text>
          </Box>

          {updatedImpactAggregationProperties && 
           <Box pl={8} mr={12} w="104px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
             <Tooltip label={updatedImpactAggregationProperties[0]?.label || 'N/A'}>
               <Text color="#585C68" fz="12px" lineClamp={1}>
                 {updatedImpactAggregationProperties[0]?.label || 'N/A'}
               </Text>
             </Tooltip>
             
             <Text fw={600} fz="12px" lineClamp={1} color="#212529">
               {firstImpactValue ? formatBigMonetaryValueIdeas(firstImpactValue, firstPrefix)  : 'N/A'}
              
             </Text>
           </Box>
          }
          {displayState?.confidenceLevel && confidenceLevel && <Stack align="center">
            {confidenceLevel === 'Strong' && <ConfidenceLevelIcon line1Color="#0BAC4D" line2Color="#0BAC4D" line3Color="#0BAC4D"/>}
            {confidenceLevel === 'Medium' && <ConfidenceLevelIcon line1Color="#FF8700" line2Color="#FF8700"/>}
            {confidenceLevel === 'Weak' &&  <ConfidenceLevelIcon line1Color="#DB2525"/>}
          </Stack>}
          <Tooltip label={`Priority: ${ priority || "N/A"}`}>
            <Box>
              <Stack align="center" justify="center" ml={16} fz="14px" >
                {" "}
                <IconAlertOctagon size={16}  color={color} />{" "}
              </Stack>
            </Box>
          </Tooltip>
        </Flex>

        {!isMatch ? (
          <Flex w="20px" mr={18} ml={16}>
            <ActionIcon
              onClick={() => {
                toggleFavourites();
              }}
            >
              <IconStar
                color={auth0.user && starred ? "#FDAE1680" : "#D1D5DB"}
                fill={auth0.user && starred ? "#FDAE1680" : "#fff"}
                size={16}
                strokeWidth={2}
              />
            </ActionIcon>
          </Flex>
        ) : (
          <Flex mr={18} ml={16} w="20px"></Flex>
        )}
      </Flex>
      
      
    </Flex>
  );
};

export default EvidenceCardNiv;












{/* <Tooltip label={item.chat?.origin} >
<Box
  mx={16}
  px={8} 
  h="32px"
  sx={{ borderRadius: "8px" }}
>
  <SourceIcon
    sourceName={item.chat?.origin || "salesforce"}
    height={13}
  />
</Box>
</Tooltip>  */}