import { useAuth0 } from "@auth0/auth0-react";
import {
  Text,
  TextInput,
  Box,
  ScrollArea,
  Menu,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconSearch, IconX } from "@tabler/icons";
import { useEffect, useState } from "react";
import {
  reassignRequest,
  searchIdeas,
} from "../../../Api";
import { IdeaItem } from "../../CreateNewModal/IdeaItem";

export function ReAssign({
  getRequestFn,
  request,
  TextInputW,
  TextInputPH,
  BoxW,
  setOpened,
  openAssignedModal,
}: {
  getRequestFn: (useLoading?: boolean) => void;
  request: any;
  idea: any;
  TextInputW?: string;
  TextInputPH?: string;
  BoxW?: string;
  setOpened: ((bool: boolean) => void) | undefined;
  openAssignedModal: (idea: any) => void
}) {
  const auth0 = useAuth0();
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAssign = (idea: any, request: any) => {
    setLoading(true);
    if (request.featureId) {
      reassignRequest(idea._id, request._id, auth0)
        .then(() => {
          getRequestFn(false);
          openAssignedModal(idea);
        })
        .catch((e) => {
          console.log(e);
          showNotification({
            title: "Error re-assigning request",
            color: "red",
            message: "please try again...",
            icon: <IconX size="1.1rem" />,
            autoClose: 1200
          });
        })
        .finally(() => setLoading(false));
    } else {
      openAssignedModal(idea);
    }
  };

  const doSearch = async () => {
    searchIdeas(query, auth0)
      .then((results: any[]) => {
        if (results.length > 0) {
          setOpen(true);
        } else {
          setOpen(false);
        }
        setSearchResults(results);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query !== "") {
        doSearch();
      } else {
        setSearchResults([]);
        setOpen(false);
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [query]);


  return (
    <div
      onFocusCapture={() => setOpen(true)}
      onBlurCapture={() => setOpen(false)}
    >
      <Menu
        opened={open}
        shadow="md"
        position="bottom-start"
        closeOnClickOutside={true}
      >
        <Menu.Target>
          <TextInput
            w={TextInputW || "28vw"}
            placeholder={TextInputPH || "Search and assign idea..."}
            icon={<IconSearch size={14} />}
            radius="md"
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
          />
        </Menu.Target>
        <Menu.Dropdown>
          <ScrollArea style={{ height: 300, overflowX: "unset" }}>
            <Box w={BoxW || "100%"}>
              {searchResults.map((idea: any) => (
                <IdeaItem
                  key={idea._id}
                  reAssign
                  loading={loading}
                  fromCreateIdea={false}
                  assignFunc={(e) => {
                    setOpened?.(false);
                    handleAssign(idea, request);
                  }}
                  idea={idea}
                />
              ))}
            </Box>
          </ScrollArea>
          {searchResults.length > 0 && (
            <Box
              px="sm"
              py="xs"
              sx={{
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <Text size="sm">{searchResults.length} results found</Text>
            </Box>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
