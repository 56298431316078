import { Box } from '@mantine/core'

const ProgressBarPart = ({widthPercentage, color}:{widthPercentage: number, color: string}) => {
  return (
    <Box
      w={`${widthPercentage || 0}%`}
      bg={color}
    ></Box>
  )
}

export default ProgressBarPart