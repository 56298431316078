import { useContext } from "react";
import {
  Menu,
  Highlight,
  Box,
  UnstyledButton,
  Text,
  Group,
} from "@mantine/core";
import CheckboxFilter from "./types/CheckboxFilter";
import DateFilter from "./types/DateFilter";
import NumberFilter from "./types/NumberFilter";
import BooleanFilter from "./types/BooleanFilter";
import FiltersContext from "./context/FiltersContext";
import { Filter, ValueLabelPair } from "./FiltersMenu";
import FetchableCheckboxFilter from "./types/FetchableCheckboxFilter";
import FilterFooter from "./FilterFooter";
import { capitalizeFirstLetters } from "../../../utils";
import { findFilterByName } from "../../../context/helpers";
import SyncContext from "../../../context/SyncContext";

interface ChildMenuProps {
  label: string;
  initialfilters: any;
  filter: any;
  MenuType: "idea" | "evidence";
  isChildFilters?: boolean;
  resetFilters: (MenuType: "idea" | "evidence", selectedParentFilter?: string | null) => void;
}

// interface FilterOneKey {
//   type: string;
//   name: string;
//   parent: string;
//   options: any[];
//   formatter?: string;
//   operations: ValueLabelPair[];
//   ChildFilters?: Filter;
//   childFilters?: Filter;
//   Label?: string;
// }

const ChildMenu = ({
  label,
  initialfilters,
  filter,
  MenuType,
  resetFilters,
  isChildFilters = false,
}: ChildMenuProps) => {
  const { type, childFilters, name, parent } = filter;

  const {
    selectedParentFilter,
    setSelectedParentFilter,
    setOpenedMenuType,
    activeFilters,
    parentQuery,
  } = useContext(FiltersContext);

  const renderChildFilters = (_childFilters: any) => {
    return Object.keys(_childFilters).map((filterName) => (
      <ChildMenu
        key={_childFilters[filterName].parent}
        label={_childFilters[filterName].label} // Pass the filter name as the 'name' prop
        initialfilters={initialfilters}
        filter={_childFilters[filterName]} // Pass the filter object
        MenuType={MenuType}
        isChildFilters={true}
        resetFilters={resetFilters}
      />
    ));
  };

  const selectedParent = selectedParentFilter?.split(".")[0];
  const selectedChild = selectedParentFilter?.split(".")[1];
  // console.log({selectedParentFilter})
  const isChildOfSelectedParent = parent?.split(".")[0] === selectedParent;
  const isSelectedChild =
    selectedChild && parent?.split(".")[1] === selectedChild;

  const bgColor =
    selectedParentFilter &&
    ((isChildOfSelectedParent && !isChildFilters) ||
      (isChildFilters && isSelectedChild))
      ? "#F8F9FA"
      : undefined;

  // console.log({selectedChild})
  //@ts-ignore
  // isChildOfSelectedParent && 
  // console.log({filter, name, selectedParentFilter, selectedParent, selectedChild,  isChildOfSelectedParent: name?.split('.'), isSelectedChild: name?.split('.'), selectedChild, bgColor})
  return (

    <Menu
      key={name}
      shadow="md"
      radius="md"
      width={260}
      position="right-start"
      opened={selectedParentFilter?.includes('.') ? selectedParent == parent || selectedParentFilter == parent : selectedParentFilter == name}
      onOpen={() => setSelectedParentFilter(parent)}
      onClose={() =>{
        setSelectedParentFilter(null)
        !selectedParentFilter && setOpenedMenuType(null)
      }}
    >
      <Menu.Target>
        <Box key={name} w={"100%"}>
          <UnstyledButton
            key={name}
            name={name}
            px="sm"
            w={"-webkit-fill-available"}
            py={6}
            mx={5}
            mb={5}
            sx={{
              borderRadius: 8,
              backgroundColor: bgColor,
              ":hover": {
                background: "#F8F9FA",
              },
            }}
          >
            <Group noWrap>
              <Text lineClamp={1}>
                <Highlight highlight={parentQuery}>{label ? label : capitalizeFirstLetters(name)}</Highlight>
              </Text>

              {activeFilters.find((aF) => {
                // Split the parent identifier into parts on the dot
                const parentParts = aF.parent.split(".");
                // Check if any part of the parent identifier matches the name or if it matches entirely
                return ((
                  parentParts.includes(name) || (isChildFilters && aF.parent === name)
                ) && aF?.selected && aF?.selected?.length > 0);
              }) && (
                <span
                  style={{
                    width: 8,
                    height: 8,
                    background: "#0BAC4D",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Group>
          </UnstyledButton>
        </Box>
      </Menu.Target>
      <Menu.Dropdown sx={{ padding: 0, paddingTop: 5 }}>
        {type === "parent" && childFilters ? (
          renderChildFilters(childFilters)
        ) : type === "checkbox" ? (
          <CheckboxFilter initialfilters={initialfilters} MenuType={MenuType} resetFilters={resetFilters} />
        ) : type === "fetchableCheckbox" ? (
          <FetchableCheckboxFilter MenuType={MenuType} resetFilters={resetFilters} />
        ) : type === "date" ? (
          <DateFilter MenuType={MenuType} />
        ) : type === "number" ? (
          <NumberFilter MenuType={MenuType} />
        ) : type === "boolean" ||  type === "userIdIn" ? (
          <BooleanFilter MenuType={MenuType} />
        ) : null}
        {type === "parent" && <FilterFooter
          onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
        />}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ChildMenu;
