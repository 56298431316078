import { Box, Flex, Stack, Text, Checkbox, Group } from "@mantine/core";
import { useContext, useEffect, useState } from "react";

import FiltersContext from "../../../ideas/filters/context/FiltersContext";


import DiscoveryContext from "../../DiscoveryContext";
import { findKeyByPerspectiveDataType } from "../../../ideaNiv/NewIdeaView";
import { SingleSideCheckboxDiscovery } from "./SingleSideCheckboxDiscovery";
import { findFilterByName } from "../../../../context/helpers";
import SyncContext from "../../../../context/SyncContext";

const PerspectiveListDiscovery = () => {
  const { ideasFiltersConfig } = useContext(SyncContext);

  const { perspectiveData, type, ideaId } =
    useContext(DiscoveryContext);

  /* console.log({perspectiveData: perspectiveData[type],type }) 

  console.log(perspectiveData) */ 

  const { activeFilters, setActiveFilters } = useContext(FiltersContext);

  const [allEvidenceChecked, setAllEvidenceChecked] = useState(false);
  const [noEvidenceChecked, setNoEvidenceChecked] = useState(true);
  const dataArr = perspectiveData[type]?.dataArr;


  const matchesCount = perspectiveData[type]?.matchesCount;

  const { key } = findKeyByPerspectiveDataType(type);
  /* console.log({ type, key }) */

  /* console.log({requestsCount, inboxCount, matchesCount, key}) */

  const handleCheckboxChange = (selectedArray: string[]) => {
    setActiveFilters && setActiveFilters((prevFilters) => {
      const CurrentFilterConfig = findFilterByName(key, 'evidence', ideasFiltersConfig)

      const index = prevFilters.findIndex((filter) => filter.parent === key);
      if (index !== -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[index] = {
          ...CurrentFilterConfig,
          ...updatedFilters[index],
          selected: selectedArray,
        };
        return updatedFilters;
      } else {
        // Add a new filter if it does not exist (optional based on your logic)
        return [
          ...prevFilters,
          {
            ...CurrentFilterConfig,
            selected: selectedArray,
          },
        ];
      }
    });
  };

  const handleDisplayAll = (click: boolean) => {
    const allValues = dataArr?.map((a: any) => (a = a.value.toLowerCase()));

    setActiveFilters((prevFilters) => {
      const CurrentFilterConfig = findFilterByName(key, 'evidence', ideasFiltersConfig)

      const index = prevFilters.findIndex((filter) => filter.parent === key);
      if (index !== -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[index] = {
          ...CurrentFilterConfig,
          ...updatedFilters[index],
          selected: click ? allValues : [],
        };
        return updatedFilters;
      } else {
        // Add a new filter if it does not exist (optional based on your logic)
        return [
          ...prevFilters,
          {
            ...CurrentFilterConfig,
            selected: click ? allValues : [],
          },
        ];
      }
    });
  };

  const currentValues =
    activeFilters.find((f) => f.parent.toLowerCase() === key)?.selected || [];

  function arraysContainSameValues(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  useEffect(() => {
    const perspectiveArr = perspectiveData[type]?.dataArr;
    const activePerspectiveFilterValues = activeFilters.find((f) => f.parent.toLowerCase() === key)?.selected || [];

    if (activePerspectiveFilterValues.length !== 0){
      setNoEvidenceChecked(false)

    }

    else if (activePerspectiveFilterValues.length === 0){
      setNoEvidenceChecked(true)

    }

    if (perspectiveArr && perspectiveArr.length > 0) {
      const allValues = perspectiveArr?.map(
        (a: any) => (a = a.value.toLowerCase())
      );
      
      /* activeFilters.find((f) => f.parent.toLowerCase() === key)?.selected || []; Commented out because it appears twice- should be a mistake */
      const allValuesSelected = arraysContainSameValues(
        allValues,
        activePerspectiveFilterValues
      );
      if (allValuesSelected) {
        setAllEvidenceChecked(true);
      } else if (!allValuesSelected) {
        setAllEvidenceChecked(false);
      }
    }
  }, [perspectiveData, activeFilters, key, ideaId]);


  return (
    <Box my="23px" >
      <Group mb={24} position="apart">
        <Group>
          <Checkbox
            indeterminate={!allEvidenceChecked && !noEvidenceChecked}
            checked={allEvidenceChecked}
            onChange={(event) => {
              setAllEvidenceChecked(event.currentTarget.checked);
              handleDisplayAll(event.currentTarget.checked);
            }}
            color="indigo"
            label={
              <Text fz="12px" lineClamp={1}>
                {" "}
                Display all evidence
              </Text>
            }
          />
        </Group>

        <Flex>
      
          <Flex ml="10px">
            <Text mr="4px"  color="#888D9B" fz="12px" fw={600}>
                Suggested
            </Text>
            <Text color="#9F9AF8" fz="12px" fw={600}>
              {matchesCount || 0}
            </Text>
          </Flex>
        
        </Flex>
      </Group>
      <Checkbox.Group value={currentValues} onChange={handleCheckboxChange}>
        {dataArr &&
          dataArr.length > 0 &&
            dataArr.map((item: any, index: number) =>
              <SingleSideCheckboxDiscovery
                key={index}
                item={item}
                
              />
            )}
      </Checkbox.Group>
    </Box>
  );
};

export default PerspectiveListDiscovery