import { Box, Group, TextInput, ActionIcon, UnstyledButton, Text } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons";
import { useState, useEffect } from "react";
import TabMenu from "./TabMenu";
import { showNotification } from "@mantine/notifications";

const RequestsTab = ({
  view,
  isActive,
  onTabChange,
  upsertViews,
}: {
    view: any;
    isActive: boolean;
    onTabChange(value: string): void;
    upsertViews: (body: any, func: (res: any) => void) => void;
  }) => {
  const { hovered, ref } = useHover();
  const [onEdit, setOnEdit] = useState(false);
  const [viewName, setViewName] = useState(view.name);
  
  useEffect(() => {
    setOnEdit(false);
  }, [isActive]);
  
  return (
    <Box
      ref={ref}
      sx={{
        borderBottom: `${isActive ? "2px" : "1.5px"} solid`,
        borderColor: isActive ? "#5b60e3" : "#f1f1f1",
        ":hover": {
          backgroundColor: "#f9fafb",
          borderBottom: "2px solid #5b60e3",
        },
        marginBottom: "-2px",
      }}
    >
      {onEdit && isActive ? (
        <Group spacing={0}>
          <TextInput
            h={35}
            pl="sm"
            pr={3}
            onChange={(event) => {
              setViewName(event.currentTarget.value);
            }}
            variant="unstyled"
            value={viewName}
            withAsterisk
            w={100}
          />
          <ActionIcon
            color="#5b60e3"
            size="xs"
            mr={5}
            onClick={() => {
              upsertViews({ name: viewName, _id: view._id }, (res: any) => {
                console.log(res);
                setOnEdit(false);
                showNotification({
                  title: "Saved successfully!",
                  color:"teal",
                  message: "view name saved successfully",
                  icon:<IconCheck size="1.1rem" />,
                  autoClose: 1200
                })
              });
            }}
          >
            <IconCheck size={16} />
          </ActionIcon>
        </Group>
      ) : (
        <UnstyledButton
          py={6}
          px={hovered && !view?.isGlobal ? "sm" : "lg"}
          key={view._id}
          value={`${view._id}`}
          onClick={() => onTabChange(view._id)}
        >
          <Group spacing={0}>
            <Text span size="sm" sx={{
              maxWidth: hovered ? '18ch' : '14ch', 
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {view.name}
            </Text>
            {hovered && (
              <TabMenu
                item={view}
                hovered={hovered}
                setOnEdit={setOnEdit}
                upsertViews={upsertViews}
              />
            )}
          </Group>
        </UnstyledButton>
      )}
    </Box>
  );
};
export default RequestsTab;