import ReactTimeAgo from "react-time-ago";
import { getQuarter } from "../../../../utils";
import {  Text } from "@mantine/core";
// CommitmentsCell Component
export const CommitmentsCell: React.FC<any> = ({
  data,
  isAllowedToEditDates,
}) => {
  const closestDate = data.aggregations?.committed?.closest;
  
  if (!closestDate) {
    return <Text></Text>;
  }
  
  if (!isAllowedToEditDates) {
    return <Text>{getQuarter(new Date(closestDate))}</Text>;
  } else {
    return <ReactTimeAgo date={new Date(closestDate)} />;
  }
};