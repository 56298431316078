import { Flex, Text } from "@mantine/core";

export const TemplateCell: React.FC<any> = ({ data, renderParams }) => {
  const { fields, template } = renderParams;
  
  const fieldValues = Object.keys(fields).reduce((acc, key) => {
    const dataPath = fields[key];
    const value = dataPath
      .split(".")
      .reduce((obj:any, prop:any) => (obj ? obj[prop] : undefined), data);
    acc[key] = value;
    return acc;
  }, {} as { [key: string]: any });
  
  let renderedContent = template;
  Object.keys(fieldValues).forEach((key) => {
    const regex = new RegExp(`{${key}}`, "g");
    const value = fieldValues[key]?.toLocaleString();
    renderedContent = renderedContent.replace(
      regex,
      value !== undefined && value !== null ? value : ""
    );
  });
  
  return (
    <Flex justify="center" align="center">
      <Text>{renderedContent}</Text>
    </Flex>
  );
};