import { useContext, useEffect, useState } from "react";
import {Box, Flex, Group, Text} from "@mantine/core";
import ImpactPieChart from "./ImpactPieChartDiscovery2";
import CompaniesList from "./Companies/CompaniesListDiscovery2";
import SyncContext from "../../../context/SyncContext";
import DiscoveryContext from "../DiscoveryContext";
import { calculateImpactResults, getNestedFieldValue } from "../../ideaNiv/helpers/calculateImpactResults";
import ImpactPieChartDiscovery2 from "./ImpactPieChartDiscovery2";


interface Company {
  _id: string;
  arr?: number;
  name: string;
}

interface Chat {
  _id: string;
  itemType?: string;
  amount?: number;
}

interface Request {
  company?: Company | null;
  chat?: Chat | null;
}

const ImpactDiscovery2: React.FC = () => {
  const { impactAggregationProperties } = useContext(SyncContext);
  const {

    displayedMatches,
    displayedRequestsMatches,
    ideaId,
    idea,
    showmatches,
    showValidated,

  } = useContext(DiscoveryContext);

  const [impactData, setImpactData] = useState<Record<string, any>>({});
  const [dynamicCount, setDynamicCount] = useState<number>(0);
  const [dynamicOrgIds, setDynamicOrgIds] = useState<string[]>([]);

  useEffect(() => {

 

    const matchesCopy =  (showmatches && !showValidated)
      ? [...displayedMatches]
      : showmatches && showValidated
        ? [
          ...displayedRequestsMatches,
          ...displayedMatches,
        ]
        : [];

    

    // Collect company IDs from both pending and validated requests
    const allCompanyIdsSet = new Set<string>();

    const collectCompanyIds = (requests: Request[]) => {
      requests.forEach((request) => {
        const companyId = getNestedFieldValue(request, "company._id");
        if (companyId && companyId !== 'N/A') {
          allCompanyIdsSet.add(companyId);
        }
      });
    };

    collectCompanyIds(matchesCopy);
    

    // Update state with the total count and list of company IDs
    setDynamicCount(allCompanyIdsSet.size);
    setDynamicOrgIds(Array.from(allCompanyIdsSet));

    const updatedImpactAggregationProperties = impactAggregationProperties.map((prop:any) => ({
      ...prop,
      propertyName: prop.propertyName.replace('cxItem', 'chat'),
      fieldPath: prop.fieldPath.replace('cxItem', 'chat'),
      groupBy: prop.groupBy ? prop.groupBy.replace('cxItem', 'chat') : prop.groupBy,
    }));
    
    // console.log({ updatedImpactAggregationProperties });
    
    // Proceed with calculating impact
    const impactResults = calculateImpactResults([], matchesCopy, updatedImpactAggregationProperties, idea);

    setImpactData(impactResults);
  }, [

    displayedMatches,
    displayedRequestsMatches,
    ideaId,
    showmatches,
    showValidated,
 
  ]);

  return (
    <Box px={36} py={24}>
      <Flex mb={4}>
        <Text fw={600} fz={14} color="#212529" mb={15} mr={4}>
            Impact:
        </Text>
        <CompaniesList
          dynamicCount={dynamicCount}
          dynamicOrgIds={dynamicOrgIds}
        />
      </Flex>

      <Group grow>
        {impactAggregationProperties
          .sort((a: any, b: any) => (a.impact?.index || 0) - (b.impact?.index || 0))
          .map((property: any) => {
            const { label, propertyName, impact } = property;
            const impactResult = impactData[propertyName] || {};

            return (
              <ImpactPieChartDiscovery2
                key={propertyName}
                title={[label]}
                validatedColor={
                  showValidated ? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"
                }
                pendingColor={
                /* showmatches 
                  ? "rgba(92, 92, 235, 0.6)"
                  : */ "#5C5CEB"
                }
                monetaryValue={impactResult.total}
                validatedMonetaryValue={impactResult.validatedTotal}
                customerCount={impactResult.customerCount}
                validatedCustomerCount={impactResult.validatedCustomerCount}
                prefix={impact?.prefix}
              />
            );
          })}
      </Group>
    </Box>
  );
};

export default ImpactDiscovery2;