import { Box, Title, Divider, Text } from "@mantine/core";

const ImportResults = ({ importRes }: { importRes: any }) => {
  if (!importRes) {
    return null;
  }
  console.log({ ImportResults: importRes });
  return (
    <Box p="md">
      <Title order={4} mb="md">
        Import Results
      </Title>

      <Text size="xs">Import SessionId: {JSON.stringify(importRes)}</Text>
    </Box>
  );
};
export default ImportResults;
