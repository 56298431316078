import { Flex } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";

// PriorityCell Component
export const PriorityCell: React.FC<any> = ({ data, ideaPriorities, col }) => {
  const priority = getNestedFieldValue(data, col.accessor);
  const defaultPriority = ideaPriorities?.find((p: any) => p?.isDefault);
  const selectedPriority = priority
    ? ideaPriorities?.find(
      (p: any) =>
        p?.key?.toLowerCase() === priority?.toLowerCase() ||
            p?.label?.toLowerCase() === priority?.toLowerCase()
    )
    : {
      color: "#ccc",
      index: 1,
      key: "Unassigned",
      label: "Unassigned",
    };
  
  return (
    <Flex>
      <div
        style={{
          marginTop: "5px",
          borderRadius: "8px",
          width: "15px",
          height: "15px",
          marginRight: "10px",
          backgroundColor:
              selectedPriority?.color || defaultPriority?.color || "#ccc",
        }}
      ></div>
      <div>
        {selectedPriority?.label || defaultPriority?.label || "Unassigned"}
      </div>
    </Flex>
  );
};