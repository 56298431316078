import { useEffect, useState } from "react";
import { Badge, ScrollArea } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { listTeams, createTeam } from "../../Api";
import {
  Title,
  Box,
  Text,
  Grid,
  Paper,
  TextInput,
  Table,
  Flex,
  Menu,
  ActionIcon,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import {
  IconSearch,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons";
import { getConnectedUserRole } from "../../utils";
import NewTeamDropdown from "./NewTeamDropdown";
import { listMembers, deleteTeam, saveMember, deleteMember } from "../../Api";
import { showNotification } from "@mantine/notifications";

const Teams = () => {
  const auth0 = useAuth0();
  const [teams, setTeams] = useState<any[]>([]);
  const [usersObj, setUsersObj] = useState<any[]>([]);



  const loadTeams = () => {
    listTeams(auth0)
      .then((teams) => {
        setTeams(teams || []);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadTeams();
    loadMembers();
  }, []);

  const loadMembers = () => {
    listMembers(auth0)
      .then((users) => {
        setUsersObj(users);
      })
      .catch((error) => console.log(error));
  };

  const openDeletePopup = (teamId: string) => {
    openConfirmModal({
      title: "Delete Team",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this team?</Text>
      ),
      labels: { confirm: "Delete team", cancel: "No, don't delete it" },
      confirmProps: { color: "red" },
      closeOnCancel: true,
      closeOnConfirm: true,
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteTeam(teamId, auth0)
          .then(() => {
            loadTeams();
          })
          .catch((error) => console.log(error));
      },
    });
  };

  const onCreateTeam = (team: { name: string; description: string }) => {
    createTeam(team, auth0)
      .then(() => {
        loadTeams();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const saveNewMember = (teamId: string, memberId: string) => {
    saveMember(teamId, memberId, auth0).then(() => {
      loadTeams();
      showNotification({
        title: "Members added to team",
        message: "Members added successfully",
        autoClose: 1200
      });
    });
  };
  const deleteMemberFromTeam = (teamId: string, memberId: string) => {
    deleteMember(teamId, memberId, auth0).then(() => {
      loadTeams();
      showNotification({
        title: "Member deleted from team",
        message: "Member deleted successfully",
        autoClose: 1200
      });
    });
  };

  const role = getConnectedUserRole(auth0);
  const canEdit = role === "admin";
  const teamsRows = teams.map((team: any) => {
    const users: any[] = [];
    const memberIds: any[] = team?.memberIds || []
    memberIds.forEach((m: any) =>{

      const user = usersObj?.find((u: any) => u._id === m)
      users.push(user)
    })
    const membersInTeam = users ?? [{name: '', _id:'', label:''}];
    const membersInTeamNames: string[] = membersInTeam.map((m: any) => m?.name);
    const membersData = usersObj.map((member: any) => ({
      name: member.name,
      value: member._id,
      label: member.name,
    }));
    const membersFiltered = membersData.filter(
      (n) => !membersInTeamNames.includes(n.name)
    );
    return (
      <tr key={team._id}>
        <td>
          <Flex gap="md" justify="space-between" align={"center"}>
            <Flex align={"center"}>
              <Box ml="md" miw={150} mih={44}>
                <Text weight={"bold"} color={"#111928"}>
                  {team.name}
                </Text>
                <Text color={"#111928"}>{team.description}</Text>
              </Box>
            </Flex>
            <Flex direction="row" align={"center"}>
              <Menu trigger="hover" openDelay={150} closeDelay={50}>
                <Menu.Target>
                  <Badge variant="outline" fz="xs" h="22px" mr={70}>
                    {membersInTeam.length} Members
                  </Badge>
                </Menu.Target>
                <Menu.Dropdown>
                  <ScrollArea.Autosize
                    maxHeight={250}
                    style={{ overflowX: "unset" }}
                    w={180}
                  >
                    {membersInTeam.map((m: any) => (
                      <Menu.Item
                        onClick={() => deleteMemberFromTeam(team._id, m._id)}
                      >
                        <Flex direction="row" justify={"space-between"}>
                          <Text>{m?.name}</Text>
                          <IconX size={10} />
                        </Flex>
                      </Menu.Item>
                    ))}
                  </ScrollArea.Autosize>
                </Menu.Dropdown>
              </Menu>
              <Menu>
                <Menu.Target>
                  <Flex direction="row" mx={"lg"} align={"center"}>
                    <IconPlus size={16} />
                  </Flex>
                </Menu.Target>
                <Menu.Dropdown>
                  <ScrollArea.Autosize
                    maxHeight={250}
                    style={{ overflowX: "unset" }}
                    w={180}
                  >
                    {membersFiltered.map((m: any) => (
                      <Menu.Item
                        onClick={() => saveNewMember(team._id, m.value)}
                      >
                        <Flex direction="row" justify={"space-between"}>
                          <Text>{m?.name}</Text>
                          <IconPlus size={12} />
                        </Flex>
                      </Menu.Item>
                    ))}
                  </ScrollArea.Autosize>
                </Menu.Dropdown>
              </Menu>
              <ActionIcon
                sx={{
                  backgroundColor: "transparent",
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                mx={"lg"}
                onClick={() => openDeletePopup(team._id)}
              >
                <IconTrash color={"red"} size={16} />
              </ActionIcon>
            </Flex>
          </Flex>
        </td>
      </tr>
    );
  });

  return (
    <Box pt={"md"}>
      <Title size={"xl"} pl={"md"}>
        Teams
      </Title>
      <Flex
        direction="row"
        wrap="wrap"
        justify="flex-end"
        align="flex-start"
        mx="sm"
      >
        {canEdit && <NewTeamDropdown onNewTeamSubmitted={onCreateTeam} />}
      </Flex>
      <Box p={"xl"} mt={"xs"} bg={"#F1F3F5"}>
        <Paper
          p="md"
          radius="md"
          mt={"lg"}
          withBorder
          sx={{ padding: "0 !important" }}
        >
          <Box>
            <Grid justify="space-between" align="flex-end">
              <Grid.Col span={4}>
                <TextInput
                  mt={30}
                  ml={30}
                  mb={15}
                  radius="md"
                  placeholder="Search"
                  // value={parentQuery}
                  // onChange={(event: any) => setParentQuery(event.currentTarget.value)}
                  icon={<IconSearch size={14.5} />}
                  sx={{
                    input: {
                      backgroundColor: "#F9FAFB",
                      ":focus": {
                        borderColor: "#b1b1b1;",
                      },
                    },
                  }}
                />
              </Grid.Col>
            </Grid>
          </Box>
          <Table
            sx={{
              "thead tr:first-of-type": {
                borderColor: "#dee2e6",
                background: "#F3F4F6",
              },
              "tbody tr:last-of-type": {
                borderBottom: " 1px solid #dee2e6",
              },
              td: {
                textAlign: "left",
              },
              "tbody tr td": {
                padding: "13px 10px",
                borderBottom: "1px solid #dee2e6",
                fontSize: "14px",
              },
              "tbody tr:hover .flag:after": {
                borderRight: " 25px solid #f5f5f5",
              },
            }}
          >
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>{teamsRows}</tbody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
};

export default Teams;
