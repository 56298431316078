import React, { useContext, useState } from "react";
import { Group, Tooltip, Button, Indicator, Sx } from "@mantine/core"; // Assuming you are using Mantine
import { IconFilter } from "@tabler/icons";
import FiltersMenu, { Filter } from "../filters/FiltersMenu";
import FilterBadge from "../filters/FilterBadge";
import { includes } from "lodash";
import { evidenceParents } from "../filters/FiltersSpec";
import FiltersContext from "../filters/context/FiltersContext";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import SyncContext from "../../../context/SyncContext";
import { findFilterByName } from "../../../context/helpers";

// Passing all states and handlers as parameters to the FiltersMenuWrapper
const FiltersMenuWrapper = ({
  activeFilters,
  setActiveFilters,
  selectedView,
  MenuType,
  targetSx
}: {
  activeFilters: any[];
  setActiveFilters: (filters: any[]) => void;
  selectedView: string;
  MenuType: "idea" | "evidence";
  targetSx?: Sx | (Sx | undefined)[] | undefined
}) => {
  const {ideasFiltersConfig } = useContext(SyncContext);
  const currentTypeActiveFilters = activeFilters
    .filter( (filter) => findFilterByName(filter.parent?.split(".").pop(), MenuType, ideasFiltersConfig))
    .map(filter => ({...filter, ...findFilterByName(filter.parent?.split(".").pop(), MenuType, ideasFiltersConfig)}));
    
  // console.log({MenuType, currentTypeActiveFilters, activeFilters,})

  return (
    <>
      <FiltersMenu
        MenuType={MenuType}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        Target={
          <Tooltip label={`Filter by ${capitalizeFirstLetter(MenuType)} attributes`} withArrow>
            <Button
              sx={{
                height: 32,
                borderColor:"#D8D8DB",
                color: "black",
                fontSize:12,
                fontWeight:500,
                ":hover":{
                  backgroundColor:"#5C5CEB1A"
                },
                ...targetSx
              }}
              variant="outline"
              radius="sm"
              px={8}
              leftIcon={
                <Indicator zIndex={5} disabled={currentTypeActiveFilters.length == 0} inline label="" size={8} offset={3} color="#0BAC4D">
                  <IconFilter strokeWidth={1} size={18}/>
                </Indicator>
              }
            >
              {capitalizeFirstLetter(MenuType)}
            </Button>
          </Tooltip>
        }
      />
      {currentTypeActiveFilters
        .map((filter, index) => (
          <FilterBadge
            key={index}
            filter={filter}
            MenuType={MenuType}
            // ownerOptions={[]}
            onRemoveClick={() => setActiveFilters(activeFilters.filter((item) => item.parent !== filter.parent))}
            selectedView={selectedView}
            sx={{
              marginRight: currentTypeActiveFilters.length == (index + 1) ? 4 : 0
            }}
          />
        ))}
    </>
  );
};

export default FiltersMenuWrapper;
