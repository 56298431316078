import React, {useContext, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {fetchIntegration} from "../../../Api";
import "../../../css/integrations.css";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Loader, Title} from "@mantine/core";
import SalesforceIntegration from "./salesforce/SalesforceIntegration";
import NotionIntegration from "./NotionIntegration";
import JiraIntegration from "./jira/JiraIntegration";
import ZendeskIntegration from "./zendesk/ZendeskIntegration";
import IntercomIntegration from "./intercom/IntercomIntegration";
import SlackIntegration from "./slack/SlackIntegration";
import GongIntegration from "./GongIntegration";
import {getConnectedUserRole} from "../../../utils";
import ClickUpIntegration from "./clickup/ClickUpIntegration";
import SyncContext from "../../../context/SyncContext";
import ProductBoardIntegration from "./productboard/ProductBoardIntegration";

const integrationPages: any = {
  "salesforce": {component: SalesforceIntegration},
  "intercom": {component: IntercomIntegration},
  "zendesk": {component: ZendeskIntegration},
  "jira": {component: JiraIntegration},
  "notion": {component: NotionIntegration},
  "slack": {component: SlackIntegration},
  "gong": {component: GongIntegration},
  "clickup": {component: ClickUpIntegration},
  "productboard": {component: ProductBoardIntegration}
}

const Integration = () => {
  const auth0 = useAuth0();
  const { fields } = useContext(SyncContext);
  const { type: integrationType } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [integration, setIntegration] = useState<any>({});
  const [customizations, setCustomizations] = useState<{[name: string]: any}>({})
  const navigate = useNavigate()
  const role = getConnectedUserRole(auth0);
  const canEdit = role === "admin";
  if (!canEdit) {
    navigate('/settings/integrations')
  }
  const loadIntegration = () => {
    if (!integrationType) {
      return ;
    }
    setLoading(true)
    fetchIntegration(auth0, integrationType)
      .then((integration) => {
        setIntegration(integration);
        setCustomizations(fields?.customizations ?? {});
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }
  useEffect(() => {
    loadIntegration();
  }, []);
  if (!integrationType || !integrationPages[integrationType]) {
    return <></>
  }
  if (loading) {
    return <Loader  variant="dots"  mt={"lg"} ml={"lg"}/>
  }
  const IntegrationPage = integrationPages[integrationType].component;

  return (<div>
    <Box pt={"md"} >
      <Title order={3} p={"sm"} sx={{textTransform: 'capitalize'}}>
        {integrationType}
      </Title>
      <IntegrationPage integration={integration} reloadIntegration={loadIntegration} customizations={customizations} />
    </Box>

  </div>)
}

export default Integration;
