import { Buffer } from "buffer";
import {Box, Button, Text } from "@mantine/core";

export const SetupAuth = () => {

  const getAuthUri = () => {
    const redirectUri = encodeURIComponent(
      "" + window.__RUNTIME_CONFIG__.REACT_APP_OAUTH_CALLBACK
    );

    const metadata = { integrationType: "intercom" };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString("base64");
    return `https://app.intercom.com/oauth?client_id=${window.__RUNTIME_CONFIG__.REACT_APP_INTERCOM_CLI_ID}&redirect_uri=${redirectUri}&state=${base64State}`;
  };

  return (
    <Box m={"md"}  pt={"10px"}>
      <Text>Connect your intercom</Text>
      <Button mt={"xl"} component={"a"} href={getAuthUri()}>
        Authorize
      </Button>
    </Box>
  );
};
