import { ActionIcon, Avatar, Box, Tooltip } from "@mantine/core";
import { getInitials } from "../../../../utils/getInitials";

// OwnerCell Component
export const OwnerCell: React.FC<any> = ({ data }) => {
  const owner = data.owner || {};
  const userName = owner.username || "";
  const ownerName = owner.name || "";
  const userPic = owner.picture || "";
  const name = ownerName || userName;
  const pic = userPic;
  
  return (
    <Box ml={5}>
      <Tooltip disabled={!name} label={name}>
        <ActionIcon>
          <Avatar src={pic} radius="xl">
            {getInitials(name)}
          </Avatar>
        </ActionIcon>
      </Tooltip>
    </Box>
  );
};