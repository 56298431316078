import {
  Autocomplete,
  Avatar,
  Button,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import React, { forwardRef, useState } from "react";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { useForm } from "@mantine/form";
import { upsertDomain } from "../../../../Api";
import { showNotification } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  picture: string;
  name: string;
  email: string;
}

function DomainRow({
  domain,
  members,
  setRemovedRow,
}: {
  domain: any;
  members: any;
  setRemovedRow: any;
}) {
  const auth0 = useAuth0();
  const [onEditDomain, setOnEditDomain] = useState(false);
  const form = useForm({
    initialValues: domain,
  });

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const handleEdit = (domainId: string) => {
    setOnEditDomain(true);
    form.setValues(domain);
  };

  const removeRow = (formValues: any, domainId: string) => {
    formValues.delete = true;
    sendSavedData(formValues, domainId);
    setRemovedRow(true);
  };

  const handleRemove = (domainId: string) => {
    if (
      confirm("Are you sure you want to remove this product area?") === true
    ) {
      removeRow(form.values, domainId);
    } else {
      return;
    }
  };

  const updateOwner = (
    formValues: any,
    domainId: string,
    ownerName: string,
    ownerId: string
  ) => {
    formValues.ownerName = ownerName;
    formValues.ownerId = ownerId;

    sendSavedData(formValues, domainId);
  };

  const handleSave = (domainId: string, formValues: any) => {
    domain.name = formValues.name;
    domain.value = formValues.value;
    domain.ownerName = formValues.ownerName;
    domain.ownerId = formValues.ownerId;

    setOnEditDomain(false);
    sendSavedData(formValues, domainId);
  };

  const sendSavedData = async (updateData: any, domainId: string) => {
    try {
      const body = { updateData, domainId };
      await upsertDomain(auth0, body).then((res) => {
        saveNotification();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ picture, name, email, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <Avatar src={picture} radius="xl" />

            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {email}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  return (
    <tr key={domain._id}>
      <td>
        {onEditDomain ? (
          <TextInput {...form.getInputProps("name")} />
        ) : (
          domain.name
        )}
      </td>
      <td>
        {onEditDomain ? (
          <TextInput
            {...form.getInputProps("value")}
            placeholder="Add description here..."
          />
        ) : domain && domain?.value?.length > 0 ? (
          domain.value
        ) : (
          <Text c="dimmed">No description yet...</Text>
        )}
      </td>
      <td>
        {members && members.length > 0 && (
          <Autocomplete
            placeholder="Select owner"
            itemComponent={SelectItem}
            value={form.values.ownerName || ""}
            onChange={(val) => {
              form.setFieldValue("ownerName", val);
            }}
            onItemSubmit={(item) => {
              form.setFieldValue("ownerId", item._id);
              form.setFieldValue("ownerName", item.name);
              updateOwner(form.values, domain._id, item.name, item._id);
            }}
            data={members}
            nothingFound={
              <Button fullWidth disabled leftIcon={<IconPlus size={18} />}>
                Invite member
              </Button>
            }
            filter={(value, user) =>
              (user.name &&
                user.name.toLowerCase().includes(value.toLowerCase().trim())) ||
              (user.email &&
                user.email.toLowerCase().includes(value.toLowerCase().trim()))
            }
            error={form.errors.ownerName || form.errors.ownerId}
          />
        )}
      </td>
      <td>
        {onEditDomain ? (
          <Button
            variant="subtle"
            color="dark"
            aria-label="Save"
            onClick={() => handleSave(domain._id, form.values)}
            disabled={form.values.name.length > 0 ? false : true}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="subtle"
            color="dark"
            aria-label="Edit"
            onClick={() => handleEdit(domain._id)}
          >
            Edit
          </Button>
        )}

        <Button
          variant="subtle"
          color="dark"
          leftIcon={<SourceIcon sourceName={"bin"} />}
          aria-label="Remove"
          onClick={() => handleRemove(domain._id)}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

export default DomainRow;
