import { ActionIcon, Indicator, Tooltip } from "@mantine/core";
import FiltersMenu, { ActiveFilter } from "../../../ideas/filters/FiltersMenu";
import { IconFilter } from "@tabler/icons";
import FilterBadge from "../../../ideas/filters/FilterBadge";
import { findFilterByName } from "../../../../context/helpers";
import SyncContext from "../../../../context/SyncContext";
import { useContext } from "react";

// Passing all states and handlers as parameters to the FiltersBar
const FiltersBar = ({
  activeFilters,
  setActiveFilters,
}: {
  activeFilters: any;
  setActiveFilters: React.Dispatch<React.SetStateAction<ActiveFilter[]>>;
}) => {
  const {
    ideasFiltersConfig
  } = useContext(SyncContext);

  const MenuType = "evidence"

  const currentTypeActiveFilters = activeFilters
    .filter( (filter:any) => findFilterByName(filter.parent?.split(".").pop(), MenuType, ideasFiltersConfig))
    .map((filter:any) => ({...filter, ...findFilterByName(filter.parent?.split(".").pop(), MenuType, ideasFiltersConfig)}));
  


  // const { quarters, weeks } = useContext(IdeaContext);
  
  // useEffect(() => {
  //   if (quarters.length > 0 || weeks.length > 0) {
  //     const updatedFilters = addOptionsToFilters(ideasFiltersConfig, { 
  //       quarters: [...quarters.map(
  //         (q: string) => (q.toLocaleLowerCase())
  //       ), 'older'], 
  //       weeks: weeks.map(
  //         (w: string) => (w.toLocaleLowerCase())
  //       )
  //     });
  //     console.log({updatedFilters})
  //     setIdeasFiltersConfig?.(updatedFilters);
  //   }
  // }, [quarters, weeks]);






  //---

  // useEffect(() => {
    
  // const pushed = [...quarters, "older"];
  // const lowerCasedQuarters = pushed.map(
  //   (p: string) => (p = p.toLocaleLowerCase())
  // );

  // const lowerCasedWeeks = weeks.map(
  //   (p: string) => (p = p.toLocaleLowerCase())
  // );

  // const componentsData: any = {};
  // components &&
  //   components.map((component: any) => {
  //     return (componentsData[component.name] = component);
  //   });

  // const domainsData: any = {};
  // showDomain &&
  //   domains.length &&
  //   domains.map((domain: any) => {
  //     return (domainsData[domain.name] = { ...domain, value: domain.name });
  //   });
  // const requestStatusesData: { [text: string]: string } = {};
  // ideaStatuses &&
  //   ideaStatuses.sort().map((status: any) => {
  //     return (requestStatusesData[status.key] = status.key);
  //   });
  // setMenuFiltersObj((prev: any) => ({
  //   ...prev,
  //   Account: {
  //     ...prev["Account"],
  //     ChildFilters: {
  //       ...(objectCustomizations?.account?.fields?.plan?.options
  //         ? {
  //           plan: {
  //             ...prev?.Account?.ChildFilters?.plan,
  //             options:
  //                 objectCustomizations.account.fields.plan.options.reduce(
  //                   (acc: any, option: any) => {
  //                     acc[option] = option;
  //                     return acc;
  //                   },
  //                   {}
  //                 ),
  //             operations: [],
  //             type: "checkbox",
  //           },
  //         }
  //         : {}),
  //       ...(objectCustomizations?.account?.fields?.tier?.options
  //         ? {
  //           tier: {
  //             ...prev?.Account?.ChildFilters?.tier,
  //             options:
  //                 objectCustomizations.account.fields.tier.options.reduce(
  //                   (acc: any, option: any) => {
  //                     acc[option] = option;
  //                     return acc;
  //                   },
  //                   {}
  //                 ),
  //             operations: [],
  //             type: "checkbox",
  //           },
  //         }
  //         : {}),
  //       ...(objectCustomizations?.account?.accountTypesMap
  //         ? {
  //           accountTypesMap: {
  //             ...prev?.Account?.ChildFilters?.accountTypesMap,
  //             options: objectCustomizations.account.accountTypesMap,
  //             operations: [],
  //             type: "checkbox",
  //           },
  //         }
  //         : {}),
  //     },
  //   },

  //   "Business Name": {
  //     ...prev["Business Name"],
  //     options: businessCategoryMapping,
  //   },
  //   /* Quarter: {
  //     ...prev["Quarter"],
  //     options: lowerCasedQuarters,
  //   },
  //   Week: {
  //     ...prev["Week"],
  //     options: lowerCasedWeeks,
  //   }, */
  // }));
  // }, [objectCustomizations, businessCategoryMapping, weeks, quarters]);

  return (
    <>
      <FiltersMenu
        // filters={menuFiltersObj}
        MenuType={MenuType}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        Target={
          <Tooltip label={`Filter by attributes`} withArrow>
            <ActionIcon
              sx={{
                height: 32,
                width: 32,
                borderColor: "#D8D8DB",
                color: "black",
                fontSize: 12,
                fontWeight: 500,
                ":hover": {
                  backgroundColor: "#5C5CEB1A",
                },
              }}
              variant="outline"
              radius="sm"
            >
              <Indicator
                mt={2}
                zIndex={5}
                disabled={currentTypeActiveFilters?.length == 0}
                inline
                label=""
                size={8}
                offset={3}
                color="#0BAC4D"
              >
                <IconFilter strokeWidth={1} size={18} />
              </Indicator>
            </ActionIcon>
          </Tooltip>
        }
      />
      {currentTypeActiveFilters.map((filter:any, index: number) => (
        <FilterBadge
          key={index}
          filter={filter}
          MenuType={MenuType}
          // ownerOptions={findFilterByName('Owner', 'idea', ideasFiltersConfig)?.options}
          onRemoveClick={() =>
            setActiveFilters(
              activeFilters.filter((item:any) => item.parent !== filter.parent)
            )
          }
        />
      ))}
    </>
  );
};

export default FiltersBar;
